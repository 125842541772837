import React, { useState } from 'react';
import {
    Col,
    Row,
    Accordion,
    Pagination,
    Table,
    Card,
    Button,
    Image,
    Alert,
    Modal,
} from "react-bootstrap";
import nivel1 from "../../images/nivel1.png";
import nivel2 from "../../images/nivel2.png";
import nivel3 from "../../images/nivel3.png";
import CustomSelect from './CustomSelect';
import Select from 'react-select';

const ApplicantItem = ({ applicant, applyJob, jobsApply, stores, jobs, handleShowComments }) => {

    const [ jobProcess, setJobProcess ] = useState(null);
    const [ jobStore, setJobStore ] = useState(null);
    const [ jobSelected, setJobSelected ] = useState(null);


    const [ error, setError ] = useState(false);
    const [ fetching, setFetching ] = useState(false);
    const [show, setShow] = useState(true);
    const [contratado, setContratado] = useState(false);
    const [showHireModal, setShowHireModal] = useState(false);
    const [showAssignModal, setShowAssignModal] = useState(false);


    
    const handleClose = () => {
        setShowHireModal(false);
        setShowAssignModal(false);
      };


    const submitForm = id => {
        console.log("In submit form");
        setFetching(true);
        setError(false);
        applyJob({ job_id: jobStore.value + "-" + jobSelected.value, user_id: id, user_action: "0" });
    }

    const submitFormAssign = id => {
        if(!jobProcess){
            setError(true);
            return false;
        }
        setFetching(true);
        setError(false);
        applyJob({ job_id: jobProcess.value, user_id: id, user_action: "2" });
    }

    const submitFormDelete = id => {
        setFetching(true);
        setError(false);
        applyJob({ job_id: "0", user_id: id, user_action: "1" });
    }

    // const contratar = (id) => {
    //     if (value.value === true) {
    //       setCalendario(value.value);
    //       setErrors({ ...errors, duration_period: false });
    //     } else if (value.value === false) {
    //       setCalendario(value.value);
    //       setForm({ ...form, duration_period: "Indeterminado" });
    //       setErrors({ ...errors, duration_period: false });
    //     }
    //   };

    const NE = 'No especificado'; // abreviatura

    return (
        <Card className="animate__animated animate__fadeIn">
            <Accordion.Toggle
                as={Button}
                eventKey={applicant.id}
                variant="link"
                style={{ color: "#000" }}
                className="tit-accordeon text-left"
            >
                {applicant.name} {applicant.last_name}  <span className="bolded"> {" / Desde: "} {applicant.created_at} </span>
                { applicant.user_status === 2 && <span className="badge badge-pill badge-warning ml-2"> Blacklist</span> }
                { applicant.user_status === 4 && <span className="badge badge-pill badge-success ml-2"> Contratado</span> }
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={applicant.id}>
                <Card.Body>

                    <Row>
                        <Col md={12}>
                            <ul className="list-unstyled row">
                                <li className="list-group-item col-4 border"><b>Tienda: </b> { applicant.store ? applicant.store : NE }</li>
                                <li className="list-group-item col-4 border"><b>Ubicación: </b> { applicant.city }</li>
                                <li className="list-group-item col-4 border"><b>Oficio: </b> { applicant.job ? applicant.job : NE }</li>
                                <li className="list-group-item col-4 border"><b>Disponibilidad: </b> { applicant.schedule }</li>
                                <li className="list-group-item col-4 border"><b>Contacto: </b> { applicant.contact }</li>
                                <li className="list-group-item col-4 border"><b>Email: </b> { applicant.email }</li>
                                <li className="list-group-item col-4 border">
                                    <b>Teléfono: </b>
                                    <a href={`https://api.whatsapp.com/send?phone=52${applicant.phone}`} target="_blank">{applicant.phone}</a>
                                </li>
                                <li className="list-group-item col-4 border">
                                    <a href={applicant.file} target="_blank" download>Descargar CV</a>
                                </li>
                                <li className="list-group-item col-4 border"><b>Estudios:</b> { applicant.studies }</li>
                                <li className="list-group-item col-4 border">
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-sm btn-block"
                                        style={{backgroundColor: '#0082c3'}}
                                        onClick={() => handleShowComments(applicant.id)}
                                    >
                                        Comentarios
                                    </button>
                                </li>
                                <li className="list-group-item col-8 border">
                                    <Row className="text-center">
                                        <Col md={4}>
                                            <p className="title-nivel mb-0">{applicant.sport_1_desc}</p>
                                            <Image src={{ 1: nivel1, 2: nivel2, 3: nivel3 }[applicant.sport_1_level]} className="img-nivel" />
                                        </Col>
                                        <Col md={4}>
                                            <p className="title-nivel mb-0">{applicant.sport_2_desc ? applicant.sport_2_desc : '--'}</p>
                                            <Image src={{ 1: nivel1, 2: nivel2, 3: nivel3 }[applicant.sport_2_level]} className="img-nivel" />
                                        </Col>
                                        <Col md={4}>
                                            <p className="title-nivel mb-0">{applicant.sport_3_desc ? applicant.sport_3_desc : '--'}</p>
                                            <Image src={{ 1: nivel1, 2: nivel2, 3: nivel3 }[applicant.sport_3_level]} className="img-nivel" />
                                        </Col>
                                    </Row>
                                </li>
                                <li className="list-group-item col-12 border-0">
                                    <Row>
                                        <Col md={4}></Col>
                                        <Col md={8}>
                                            {error &&
                                                <Alert className="text-center" variant="danger" onClose={() => setError(false)} dismissible>
                                                    <h6>Debes seleccionar un proceso</h6>
                                                </Alert>
                                            }
                                        </Col>
                                    </Row>
                                    <Row>

                                        {{ // swich
                                            1:
                                                <>
                                                    {/* <Col md={4}>
                                                        <label className="mt-1">Asignar proceso de selección:</label>
                                                    </Col> */}
                                                    
                                                    <Col md={4}>
                                                        <button
                                                            type="submit"
                                                            className="btn btn-primary"
                                                            style={{backgroundColor: '#0082c3'}}
                                                            //onClick={() => submitFormAssign(applicant.id)}
                                                            onClick={() => setShowAssignModal(true)}
                                                            //onClick={() => contratar(applicant.id)}
                                                            disabled={fetching}
                                                        >
                                                            {fetching && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" /> }
                                                            &nbsp;Asignar a una vacante
                                                        </button>
                                                    </Col>
                                                    <Col md={4}>
                                                        <button
                                                            type="submit"
                                                            className="btn btn-primary"
                                                            style={{backgroundColor: '#0082c3'}}
                                                            //onClick={() => submitForm(applicant.id)}
                                                            onClick={() => setShowHireModal(true)}
                                                            //onClick={() => contratar(applicant.id)}
                                                            disabled={fetching}
                                                        >
                                                            {fetching && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" /> }
                                                            &nbsp;Contratar candidato
                                                        </button>
                                                    </Col>
                                                    <Col md={4}>
                                                        <button
                                                            type="submit"
                                                            className="btn btn-primary"
                                                            style={{backgroundColor: '#0082c3'}}
                                                            onClick={() => submitFormDelete(applicant.id)}
                                                            disabled={fetching}
                                                        >
                                                            {fetching && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" /> }
                                                            &nbsp;Eliminar candidato
                                                        </button>
                                                    </Col>
                                                    {/* <Col md={6}>
                                                        <Select
                                                            menuPortalTarget={document.querySelector('body')}
                                                            options={jobsApply}
                                                            value={jobProcess}
                                                            placeholder="Seleccionar proceso:"
                                                            onChange={(value) => setJobProcess(value)}
                                                            isSearchable
                                                        />

                                                        
                                                        
                                                    </Col> */}
                                                    <Modal
                                                                show={showHireModal}
                                                                onHide={handleClose}
                                                                backdrop="static"
                                                                keyboard={false}
                                                                size="xl"
                                                        >
                                                                <Modal.Header closeButton className="bgBlue" id="cv">
                                                                <div className=" container">
                                                                    <div className="titBack text-center col-12 my-1 text-white ">
                                                                    Contratar canidato como:
                                                                    </div>
                                                                </div>
                                                                </Modal.Header>
                                                                <Modal.Body>
                                                                    {/* <Select
                                                                        //menuPortalTarget={document.querySelector('body')}
                                                                        options={jobsApply}
                                                                        value={jobProcess}
                                                                        placeholder="Seleccionar proceso:"
                                                                        onChange={(value) => setJobProcess(value)}
                                                                        isSearchable
                                                                    /> */}

                                                                    <Select
                                                                    //value={qStore}
                                                                    //styles={customStyles}
                                                                    //className=" "
                                                                    //menuPortalTarget={document.querySelector('body')}
                                                                    options={stores}
                                                                    value={jobStore}
                                                                    placeholder={"Seleccione una tienda..."}
                                                                    onChange={(value) => setJobStore(value)}
                                                                    required
                                                                    //isDisabled={qStore !== null ? true : false}
                                                                    />

                                                                    <Select
                                                                    //value={qStore}
                                                                    //styles={customStyles}
                                                                    //className=" "
                                                                    //menuPortalTarget={document.querySelector('body')}
                                                                    options={jobs}
                                                                    value={jobSelected}
                                                                    placeholder={"Seleccione un oficio..."}
                                                                    onChange={(value) => setJobSelected(value)}
                                                                    required
                                                                    //isDisabled={qStore !== null ? true : false}
                                                                    />

                                                                </Modal.Body>
                                                                <Modal.Footer>
                                                                    <Button
                                                                        variant="danger"
                                                                        onClick={() => {
                                                                        handleClose();
                                                                        }}
                                                                    >
                                                                        Cancelar
                                                                    </Button>
                                                                    <Button
                                                                        variant="primary"
                                                                        onClick={() => submitForm(applicant.id)}
                                                                    >
                                                                        Contratar
                                                                    </Button>
                                                                           
                                                                    
                                                                </Modal.Footer>
                                                    </Modal>
                                                    <Modal
                                                                show={showAssignModal}
                                                                onHide={handleClose}
                                                                backdrop="static"
                                                                keyboard={false}
                                                                size="l"
                                                        >
                                                                <Modal.Header closeButton className="bgBlue" id="cv">
                                                                <div className=" container">
                                                                    <div className="titBack text-center col-12 my-1 text-white ">
                                                                    Asignar candidato a un proceso de selección:
                                                                    </div>
                                                                </div>
                                                                </Modal.Header>
                                                                <Modal.Body>
                                                                    <Select
                                                                        options={jobsApply}
                                                                        value={jobProcess}
                                                                        placeholder="Seleccionar proceso:"
                                                                        onChange={(value) => setJobProcess(value)}
                                                                        isSearchable
                                                                    />
                                                                </Modal.Body>
                                                                <Modal.Footer>
                                                                    <Button
                                                                        variant="danger"
                                                                        onClick={() => {
                                                                        handleClose();
                                                                        }}
                                                                    >
                                                                        Cancelar
                                                                    </Button>
                                                                    <Button
                                                                        variant="primary"
                                                                        onClick={() => submitFormAssign(applicant.id)}
                                                                    >
                                                                        Asignar
                                                                    </Button>
                                                                           
                                                                    
                                                                </Modal.Footer>
                                                    </Modal>
                                                </>
                                            ,
                                            2:
                                                <Col xs={12} className="mt-1">
                                                    <Alert variant="warning text-center">
                                                        <b>Este usuario fue eliminado de la base de datos.</b>
                                                    </Alert>
                                                </Col>
                                            ,
                                            4:
                                                <Col xs={12} className="mt-1">
                                                    <Alert variant="success text-center">
                                                        <b>Este usuario fue contatado como {applicant.hired_job}</b>
                                                    </Alert>
                                                </Col>
                                        }[applicant.user_status]}
                                    </Row>
                                </li>
                              </ul>
                        </Col>
                    </Row>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}

export default ApplicantItem