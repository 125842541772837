import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { AuthContext } from "../auth/AuthContext";
import { types } from "../types/types";
import axios from "axios";
import { Loader } from "./Loader";
import { BACKEND } from "./backend";
import flecha from "./images/icons/flecha.png";

export const DivisorLogin = ({ setIsDrop, setUser, setAdmin }) => {
  const isMounted = React.useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  let history = useHistory();
  const  { dispatch }  = useContext(AuthContext);
  const token = localStorage.getItem("token");
  const baseUrl = BACKEND + "api/login";
  const [res, setRes] = useState(null);
  const [error, setError] = useState(null);
  const [msg, setMsg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [log, setLog] = useState(true);
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  if (JSON.parse(localStorage.getItem("user"))) {
    if (JSON.parse(localStorage.getItem("user")).logged === true) {
      setUser(true);
      setIsDrop(false);
    }
  }
  if (JSON.parse(localStorage.getItem("user"))) {
    if (JSON.parse(localStorage.getItem("user")).isAdmin === true) {
      setAdmin(true);
      setIsDrop(false);
    }
  }
  const changeTrue = () => {
    if (!log === true) {
      setLog(!log);
      setIsDrop(log);
    }
  };
  const changeFalse = () => {
    if (log === true) {
      setLog(false);
      setIsDrop(false);
      setTimeout(() => {
        setLog(true);
      }, 200);
    }
  };

  const regexName = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
  const regexEmail =
    /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i;
  const regexComments = /^.{1,255}$/;
  var patt = new RegExp("@decathlon");

  const validarEmailDKT = (e) => {
    var correoCorrecto = regexEmail.test(e.target.value);
    var esDKT = patt.test(e.target.value);
    if (esDKT === true) {
      dispatch({
        type: types.adminlogin,
      });
    window.location.href = BACKEND + "api/authenticate";
      // window.location.href = 'http://localhost:3000/#/cms/auth/crear-vacante?authorization=123&refresh=1';
    }

    if (!correoCorrecto) {
      setMsg("El email no existe");
    } else {
      setMsg(null);
    }
  };
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  const HandleLogin = () => {
    history.replace("/status");
  };
  const fetchData = async (e) => {
    e.stopPropagation();
    setLoading(true);
    try {
      const resp = await axios({
        url: baseUrl,
        method: "POST",
        data: form,
      });
      if (resp.status === 200) {
        if (resp.data.success === 1) {
          setRes(resp);
          setLoading(false);
          setError(null);
          let authorization = resp.data.data.access_token;
          let refresh = resp.data.data.refresh_token;
          localStorage.setItem("tokenUser", authorization);
          localStorage.setItem("refreshUser", refresh);
          HandleLogin();
          setUser(true);
          document.body.click();
        }
      }
      if (resp.data.success === 0) {
        setMsg(resp.data.message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleBlur = () => {};

  return (
    <>
      <div className="col-lg-12">
        <form autoComplete="off">
          <div className="container-fluid  text-center  pt-4 mt-1 mb-4">
            <div className="row  justify-content-around">
              <div className={`Session  ${log === true ? "actv" : ""}`}>
                INICIAR SESIÓN
              </div>
              
            </div>
            <div className="w-100" style={{ height: "10px" }}>
              <div
                className={` d-inline-block bgGray ${
                  log === true ? "bgBlue" : ""
                } w-100`}
                style={{ height: "10px" }}
                onClick={() => {
                  changeTrue();
                }}
              ></div>
            </div>
          </div>
          {log === true ? (
            loading === true ? (
              <Loader />
            ) : (
              <div className="container mt-5 mb-4 px-4">
                <div className="form-group">
                  <label htmlFor="username">Email</label>
                  <div className="container-email">
                    <input
                      type="text"
                      name="email"
                      style={{ width: '83%' }}
                      className="form-control"
                      autoComplete="off"
                      onBlur={validarEmailDKT}
                      onChange={handleChange}
                      value={form.email}
                      required
                    />
                    <a className="btn_signin"><img className="btn_signin_img" src={flecha} /></a>
                    
                </div>
                <div className="form-group">
                      <div className="row">
                        <div className="col-12">
                          {msg !== null && (
                            <span className="text-danger">{msg}</span>
                          )}
                        </div>
                      </div>
                  </div>
                  <a
                className={`Session  ${log === false ? "actv" : ""} btn-register btn-b   pl-02 `}
                onClick={() => {
                  changeFalse();
                }}
              >
                {" "}
                POSTULARSE
              
                  </a>
                </div>
                
              </div>
            )
          ) : (
            <Redirect to="/perfil" />
          )}
        </form>
      </div>
    </>
  );
};