import React, { useEffect } from 'react';

const ScrollBubbleWithAnimation = () => {
  const handleScrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
      @keyframes bounce {
        0% {
          transform: translateY(0);
        }
        50% {
          transform: translateY(-10px);  /* Mueve hacia arriba */
        }
        100% {
          transform: translateY(0);
        }
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return (
    <div
      style={styles.bubble}
      onClick={handleScrollToBottom}
      className="bubble"
    >
      <span style={styles.text}>⬇</span>
    </div>
  );
};

const styles = {
  bubble: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    backgroundColor: 'rgb(54 67 186)',
    border: 'solid 2px #fff',
    color: 'white',
    borderRadius: '50%',
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
    zIndex: 1000,
    animation: 'bounce 3s ease-in-out infinite',
  },
  text: {
    fontSize: '20px',
  },
};

export default ScrollBubbleWithAnimation;
