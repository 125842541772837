import React, { useState, useEffect } from 'react';

const Carousel = ({ items, interval = 3000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
  };


  useEffect(() => {
    const autoSlide = setInterval(nextSlide, interval);
    return () => clearInterval(autoSlide);
  }, [interval]);

  return (
    <div className="carousel">
      <div className="carousel-item">{items[currentIndex]}</div>
    </div>
  );
};

export default Carousel;

