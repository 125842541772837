import React, { useState, useRef } from "react";
import {
  Container,
  Col,
  Form,
  Jumbotron,
  Modal,
  Row
} from "react-bootstrap";
import SelectList from "./selectList";
import SelectCity from "./selecCity";
import SelectStore from "./selectStore";
import SelectSports from "./selectSports";
import { useParams } from "react-router";
import { useEffect } from "react";
import clip from "../components/images/clip.png";
import foto from "../components/images/iconFoto.png";
import checkTrue from "../components/images/checkTrue.png";
import checkClip from "../components/images/checkClip.png";
import { LoaderChico } from "./LoaderChico";
import axios from "axios";
import { Redirect } from "react-router-dom";
import SelectOficio from "./selectOficio";
import ReactGa from "react-ga4";
import { BACKEND } from "./backend";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";



const Formulario = ({jobId}) => {
  
  let job_id  = jobId.match.params.job_id;
  
  useEffect(() => {
    ReactGa.initialize("G-JKLTE0SPJH");
    ReactGa.send("/perfil/" + job_id);
    window.scrollTo(0, 0);
  }, []);

  const [form, setForm] = useState({
    name: "",
    last_name: "",
    sec_last_name: "",
    age: "",
    city: "",
    phone: "",
    email: "",
    password: "",
    studies_id: "",
    sport_1: "",
    sport_2: "",
    sport_3: "",
    contact_id: "",
    schedule_id: "",
    gender_id: "",
  });
  
  const [uploadFoto, setUploadFoto] = useState(null);
  const [uploadCV, setUploadCV] = useState(null);
  const [errors, setErrors] = useState({});
  const [loadPost, setLoadPost] = useState(false);
  const [res, setRes] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [alertError, setalertError] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [msg, setMsg] = useState(null);
  const [postulado, setPostulado] = useState(null);
  const [msgPostulado, setMsgPostulado] = useState(null);
  const [show, setShow] = useState(false);
  const [bornDate, setBornDate] = useState(null);
  const [errores, setErrores] = useState({});

  const today = new Date();
  const month = today.getMonth()+1;
  const year = today.getFullYear()-18;
  const date = today.getDate();
  const currentDate = month + "/" + date + "/" + year;
  const maxDate = new Date(currentDate + " 00:00:00 AM");

  useEffect(() => {
    if (job_id) {
      setForm({ ...form, job_id: job_id });
    }
  }, []);
  const inputCvRef = useRef();

  // VALIDAR SI TIENE SESION DE USUARIO PARA POSTULARLO EN LUGAR DE MOSTRAR EL FORMULARIO
  useEffect(() => {
    if (localStorage.getItem("tokenUser")) {
      const token = localStorage.getItem("tokenUser");
      fetchUsuario(token);
    }
  }, []);

  useEffect(() => {
    if (alertError) {
      const timer = setTimeout(() => {
        setalertError(false);
      }, 30000);

      return () => clearTimeout(timer);
    }
  }, [alertError]);

  //  FETCH DE POSTULACION A USUARIO PREVIAMENTE REGISTRADO
  const baseUrlAplicant = BACKEND + "api/applicant/apply";

  const fetchUsuario = async (token) => {
    try {
      const resp = await axios({
        url: baseUrlAplicant,
        method: "POST",
        headers: { Authorization: "Bearer " + token },
        data: {
          job_id: job_id,
        },
      });
      if (resp.data.success === 1) {
        setPostulado(true);
      }
      if (resp.data.success === 0) {
        setPostulado(false);
        setMsgPostulado(resp.data.message);
      }
    } catch (error) {
      setPostulado(false);
    } finally {
      setTimeout(() => {
        setRedirect(true);
      }, 6500);
    }
  };
  ////////// Validacion
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const onChangeGen = (value) => {
    setForm({ ...form, gender_id: value.value });
  };
  const onChangeCity = (value) => {
    setForm({ ...form, city: value.value });
  };
  const onChangeStore = (value) => {
    setForm({ ...form, optional_store: value.value });
  };
  const onChangeOficio = (value) => {
    setForm({ ...form, optional_job: value.value });
  };
  const onChangeStudies = (value) => {
    setForm({ ...form, studies_id: value.value });
  };
  const onChangeSchedule = (value) => {
    setForm({ ...form, schedule_id: value.value });
  };
  const onChangeContact = (value) => {
    setForm({ ...form, contact_id: value.value });
  };
  let spt1, spt2, spt3;
  const onChangeSports1 = (id, lvl) => {
    spt1 = JSON.stringify({ [id]: lvl });
    setForm({ ...form, sport_1: spt1 });
  };
  const onChangeSports2 = (id, lvl) => {
    spt2 = JSON.stringify({ [id]: lvl });
    setForm({ ...form, sport_2: spt2 });
  };
  const onChangeSports3 = (id, lvl) => {
    spt3 = JSON.stringify({ [id]: lvl });
    setForm({ ...form, sport_3: spt3 });
  };

  const onChangeSports4 = (id, lvl) => {
    spt2 = JSON.stringify({ [id]: lvl });
    setForm({ ...form, sport_2: spt2 });
  };
  const onChangeSports5= (id, lvl) => {
    spt3 = JSON.stringify({ [id]: lvl });
    setForm({ ...form, sport_3: spt3 });
  };

  const onChange = (date) => {
    setBornDate(date);
    setForm({ ...form, age: date});
  };

  const onChangeCV = (e) => {
    const readerC = new FileReader();
    const selectedFile = e.target.files[0];

    // only upload pdf files
    if(!selectedFile || selectedFile.type !== 'application/pdf' || selectedFile.size > 10485760){
        setUploadCV(null);
        setForm({ ...form, file_cv: ""});
        return false;
    }

    if (selectedFile) {
      readerC.addEventListener("loadstart", handleEvent);
      readerC.addEventListener("load", handleEvent);
      readerC.addEventListener("loadend", handleEvent);
      readerC.addEventListener("progress", handleEvent);
      readerC.addEventListener("error", handleEvent);
      readerC.addEventListener("abort", handleEvent);
      readerC.readAsDataURL(selectedFile);
    }
    function handleEvent(event) {
      if (event.type === "loadend") {
        setUploadCV(true);
        setForm({ ...form, file_cv: inputCvRef.current.files[0] });
      }
    }
  };

  const onChangeFoto = (e) => {
    const reader = new FileReader();
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      reader.addEventListener("loadstart", handleEvent);
      reader.addEventListener("load", handleEvent);
      reader.addEventListener("loadend", handleEvent);
      reader.addEventListener("progress", handleEvent);
      reader.addEventListener("error", handleEvent);
      reader.addEventListener("abort", handleEvent);
      reader.readAsDataURL(selectedFile);
    }
    function handleEvent(event) {
      if (event.type === "loadend") {
        setForm({ ...form, photo_user: e.target.files[0] });
        setUploadFoto(true);
      }
    }
  };
  const exp = {
    txt: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // Letras y espacios, pueden llevar acentos.
    password: /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]{6,12}$/,
    correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
    telefono: /^\d{7,14}$/, // 7 a 14 numeros.
    age: /^\d{2}$/, // 7 a 14 numeros.
    textArea: /^.{1,255}$/,
  };

  /////// SUBMIT
  const baseUrl =BACKEND+ "api/register";

  const fetchData = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("name", form.name);
      formData.append("last_name", form.last_name);
      formData.append("sec_last_name", form.sec_last_name);
      formData.append("age", form.age);
      formData.append("city", form.city);
      formData.append("phone", form.phone);
      formData.append("email", form.email);
      formData.append("password", "12345678");
      formData.append("studies_id", form.studies_id);
      formData.append("sport_1", form.sport_1);
      if (form.sport_2) {
        formData.append("sport_2", form.sport_2);
      }
      if (form.sport_3) {
        formData.append("sport_3", form.sport_3);
      }
      formData.append("contact_id", form.contact_id);
      formData.append("schedule_id", form.schedule_id);
      formData.append("gender_id", form.gender_id);
      if (form.job_id) {
        formData.append("job_id", form.job_id);
      }
      if (form.optional_store) {
        formData.append("optional_store", form.optional_store);
      }
      if (form.optional_job) {
        formData.append("optional_job", form.optional_job);
      }
      if (form.file_cv) {
        formData.append("file_cv", form.file_cv);
      }
      if (form.photo_user) {
        formData.append("photo_user", form.photo_user);
      }
      const resp = await axios({
        url: baseUrl,
        method: "POST",
        data: formData,
      });
      setRes(resp);
      setLoading(false);
      if (resp.data.success === 1) {
        setSuccess(true);
        setTimeout(() => {
          setRedirect(true);
        }, 3600);
      }
      if (resp.data.success === 0) {
        setSuccess(null);
        setalertError(true);
        setMsg(resp.data.message);
        
        setTimeout(() => {
          setalertError(true);
          setSuccess(false);
          
        }, 2600);
      }
      setError(null);
    } catch (error) {
      setError(error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  };
  
  const validarFormulario = () => {
    const nuevosErrores = {};
    
    if (!form.name) {
      nuevosErrores.name = 'Campo obligatorio';
    }
    if (!form.last_name) {
      nuevosErrores.last_name = 'Campo obligatorio';
    }
    if (!form.sec_last_name) {
      nuevosErrores.sec_last_name = 'Campo obligatorio';
    }
    if (!form.age) {
      nuevosErrores.age = 'Campo obligatorio';
    }
    if (!form.gender_id) {
      nuevosErrores.gender_id = 'Campo obligatorio';
    }
    if (!form.city) {
      nuevosErrores.city = 'Campo obligatorio';
    }
    if (!form.studies_id) {
      nuevosErrores.studies_id = 'Campo obligatorio';
    }
    if (!form.phone) {
      nuevosErrores.phone = 'Campo obligatorio';
    }
    if (!form.email) {
      nuevosErrores.email = 'Campo obligatorio';
    }
    if (!form.contact_id) {
      nuevosErrores.contact_id = 'Campo obligatorio';
    }
    if (!form.schedule_id) {
      nuevosErrores.schedule_id = 'Campo obligatorio';
    }
    if (!form.sport_1) {
      nuevosErrores.sport_1 = 'Campo obligatorio';
    }
    if (!form.optional_store) {
      nuevosErrores.optional_store = 'Campo obligatorio';
    }
    if (!form.file_cv) {
      nuevosErrores.file_cv = 'Campo obligatorio';
    }

    setErrores(nuevosErrores);
    return Object.keys(nuevosErrores).length === 0; 
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validarFormulario()) return;
    fetchData();
    setLoadPost(true);
  };
  const handleShow = (vac) => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  const closeError = () => {
    setSuccess(false);
  };
  
  return (
    <>
      <Container className="containter2 animate__animated animate__fadeIn animate__slow">
        <Jumbotron className="bg-white tit-form">
          ¡ÚNETE A NUESTRO EQUIPO!
        </Jumbotron>

        {postulado === false && (
          <Form.Row>
            <button className="EnviarError my-5  mb-5   ">
              <span className="label">
                Ocurrio un error al enviar los datos.
                <br />
                <br />
                {msgPostulado}
              </span>
            </button>
            <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />{" "}
            <br /> <br /> <br />
            &nbsp;
          </Form.Row>
        )}
        {postulado === true && (
          <Form.Row>
            <button className="EnviarSuccess  my-5  mb-5 animate__animated animate__fadeIn animate__slow">
              <span className="label">
                Su candidatura ha sido asignada a la vacante, gracias.
              </span>
            </button>
            <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />{" "}
            <br /> <br /> <br />
            &nbsp;
          </Form.Row>
        )}

        {postulado === null && (
          <Form onSubmit={handleSubmit}>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridnombre">
                <Form.Label className="formTitl">Nombre Completo</Form.Label>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridnombre">
                <Form.Control
                  type="text"
                  placeholder="Nombre(s)"
                  className="stylePlace stylePlacer"
                  name="name"
                  style={{ borderColor: errors.name == true && "#ff0000" }}
                  values={form.name}
                  onChange={handleChange}
                  autoComplete="off"
                />
               {errores.name && <p className='error'>{errores.name}</p>}
              </Form.Group>

              <Form.Group as={Col} controlId="formGridPassword">
                <Form.Control
                  type="text"
                  placeholder="Apellido Materno"
                  className="stylePlace stylePlacer"
                  name="last_name"
                  values={form.last_name}
                  onChange={handleChange}
                  style={{ borderColor: errors.last_name == true && "#ff0000" }}
                  autoComplete="off"
                />
               {errores.last_name && <p className='error'>{errores.last_name}</p>}
              </Form.Group>
              <Form.Group as={Col} controlId="formGridPassword">
                <Form.Control
                  type="text"
                  placeholder="Apellido Paterno"
                  className="stylePlace stylePlacer"
                  name="sec_last_name"
                  values={form.sec_last_name}
                  onChange={handleChange}
                  style={{ borderColor: errors.sec_last_name == true && "#ff0000" }}
                  autoComplete="off"
                />
                {errores.sec_last_name && <p className='error'>{errores.sec_last_name}</p>}
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formDatos">
                <Form.Label className="formTitl">Fecha de nacimiento</Form.Label>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} sm={2} controlId="formDatos">
              <DatePicker
                    name="age"
                    className="p-2 inputCalendar pt-2"
                    selected={bornDate}
                    onChange={onChange}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    locale="es"
                    dateFormat="dd/MM/yyyy"
                    maxDate={maxDate}
                    customInput={<input readOnly />}
                  />
              </Form.Group>
              {errores.age && <p className='error'>{errores.age}</p>}
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formDatos">
                <Form.Label className="formTitl">Datos</Form.Label>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group
                as={Col}
                controlId="formGénero"
                className="mt-4 mt-md-0"
              >
                <div
                  name="gender_id"
                  style={{
                    border: errors.gender_id == true && "1.1px solid #ff0000",
                    borderRadius: errors.gender_id == true && "5px",
                  }}
                >
                  <SelectList
                    
                    title="Género"
                    url={BACKEND+"api/data-register"}
                    cat="cat_gender"
                    onChange={onChangeGen}
                  />
                </div>
                {errores.gender_id && <p className='error'>{errores.age}</p>}
              </Form.Group>

              <Form.Group as={Col} controlId="formCiudad" className="mt-md-n4 ">
                <Form.Label className="estudio ">Ciudad</Form.Label>
                <div
                  name="city"
                  style={{
                    border: errors.city == true && "1.1px solid #ff0000",
                    borderRadius: errors.city == true && "5px",
                  }}
                >
                  <SelectCity
                    title="Ciudad"
                    url={BACKEND+"api/store-list"}
                    onChange={onChangeCity}
                  />
                </div>
                {errores.city && <p className='error'>{errores.city}</p>}
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formEstudio">
                <Form.Label className="formTitl">Formación</Form.Label>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} xs={12} sm={6} controlId="formEstudio">
                <Form.Label className="estudio">
                  Estudio realizado o cursando{" "}
                </Form.Label>
                <div
                  name="studies_id"
                  style={{
                    border: errors.studies_id == true && "1.1px solid #ff0000",
                    borderRadius: errors.studies_id == true && "5px",
                  }}
                >
                  <SelectList
                    title="Estudios"
                    url={BACKEND+"api/data-register"}
                    cat="cat_studies"
                    onChange={onChangeStudies}
                  />
                </div>
                {errores.studies_id && <p className='error'>{errores.studies_id}</p>}
              </Form.Group>

              <Form.Group
                className="disponibilidad "
                as={Col}
                xs={12}
                sm={6}
                controlId="formGridState"
              >
                <div
                  name="schedule_id"
                  style={{
                    border: errors.schedule_id == true && "1.1px solid #ff0000",
                    borderRadius: errors.schedule_id == true && "5px",
                  }}
                >
                  <SelectList
                    title="Disponibilidad de Horario"
                    url={BACKEND+"api/data-register"}
                    cat="cat_schedule"
                    onChange={onChangeSchedule}
                  />
                </div>
                {errores.schedule_id && <p className='error'>{errores.schedule_id}</p>}
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="formGridnombre">
                <Form.Label className="formTitl">
                  Deporte Pasión{" "}
                  <span className="text-muted">&nbsp;(Seleccione 3)</span>
                </Form.Label>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} xs={12} lg={4} controlId="formDeporte">
                <div
                  name="sport_1"
                  style={{
                    border: errors.sport_1 == true && "1.1px solid #ff0000",
                    borderRadius: errors.sport_1 == true && "5px",
                  }}
                >
                  <SelectSports
                    title="Deporte Practicado"
                    url={BACKEND+"api/data-register"}
                    cat="cat_sports"
                    onChange={onChangeSports1}
                  />
                </div>
                {errores.sport_1 && <p className='error'>{errores.sport_1}</p>}
              </Form.Group>

              <Form.Group as={Col} xs={12} lg={4} controlId="formDeporte">
                <div
                  style={{
                    border: errors.sport_2 == true && "1.1px solid #ff0000",
                    borderRadius: errors.sport_2 == true && "5px",
                  }}
                >
                  <SelectSports
                    title="Deporte Practicado"
                    url={BACKEND+"api/data-register"}
                    cat="cat_sports"
                    onChange={onChangeSports2}
                  />
                </div>
              </Form.Group>
              <Form.Group as={Col} xs={12} lg={4} controlId="formDeporte">
                <div
                  style={{
                    border: errors.sport_3 == true && "1.1px solid #ff0000",
                    borderRadius: errors.sport_3 == true && "5px",
                  }}
                >
                  <SelectSports
                    title="Deporte Practicado"
                    url={BACKEND+"api/data-register"}
                    cat="cat_sports"
                    onChange={onChangeSports3}
                  />
                </div>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="Tienda">
                <Form.Label className="formTitl">Tienda/corporativo</Form.Label>
              </Form.Group>
              <Form.Group as={Col} controlId="Oficio">
                <Form.Label className="formTitl">Oficio</Form.Label>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} xs={12} sm={6} controlId="Tienda" >
                <div name="optional_store">
                  <SelectStore
                    title="Tienda"
                    url={BACKEND+"api/store-list"}
                    onChange={onChangeStore}
                  />
                  {errores.optional_store && <p className='error'>{errores.optional_store}</p>}
                </div>
              </Form.Group>

              <Form.Group
                className="disponibilidad "
                as={Col}
                xs={12}
                sm={6}
                controlId="Oficio"
              >
                <div
                  name="optional_job"
                  style={{
                    border: errors.schedule_id == true && "1.1px solid #ff0000",
                    borderRadius: errors.schedule_id == true && "5px",
                  }}
                >
                  <SelectOficio
                    title="Oficio"
                    url={BACKEND+"api/jobs-offering"}
                    onChange={onChangeOficio}
                  />
                </div>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} xs={12} controlId="formContacto">
                <Form.Label className="formTitl">Contacto</Form.Label>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formPhone">
                <Form.Control
                  type="tel"
                  placeholder="Télefono"
                  className="stylePlace stylePlacer "
                  name="phone"
                  values={form.phone}
                  onChange={handleChange}
                  style={{ borderColor: errors.phone == true && "#ff0000" }}
                  autoComplete="off"
                />
               {errores.phone && <p className='error'>{errores.phone}</p>}
              </Form.Group>

              <Form.Group as={Col} controlId="formCorreo">
                <Form.Control
                  type="text"
                  placeholder="Correo Electrónico"
                  className="stylePlace stylePlacer"
                  name="email"
                  values={form.email}
                  onChange={handleChange}
                  style={{ borderColor: errors.email == true && "#ff0000" }}
                  autoComplete="off"
                />
                {errores.email && <p className='error'>{errores.email}</p>}
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group
                className="disponibilidad "
                as={Col}
                xs={12}
                sm={6}
                controlId="formContact"
              >
                <div
                  name="contact_id"
                  style={{
                    border: errors.contact_id == true && "1.1px solid #ff0000",
                    borderRadius: errors.contact_id == true && "5px",
                  }}
                >
                  <SelectList
                    title="Medio de Contacto Preferido"
                    url={BACKEND+"api/data-register"}
                    cat="cat_contact"
                    onChange={onChangeContact}
                  />
                </div>
                {errores.contact_id && <p className='error'>{errores.contact_id}</p>}
              </Form.Group>

              <div className="button-wrapper">
                <span className="label">
                  {" "}
                  <img
                    src={
                      uploadCV === null ? clip : uploadCV === true && checkClip
                    }
                    style={{
                      marginRight: "10px",
                      paddingTop: "5px",
                      width:
                        uploadCV === null
                          ? "10px"
                          : uploadCV === true && "20px",
                    }}
                    alt="clip"
                  />
                  SUBIR CV <br />
                  <div className="pdf-form">PDF</div>
                </span>
                <Form.Control
                  className="cFile"
                  name="file_cv"
                  type="file"
                  ref={inputCvRef}
                  onChange={(e) => onChangeCV(e)}
                  accept="application/pdf"
                  onClick={() => {
                    setErrors({ ...errors, file_cv: false });
                  }}
                />
                {errores.file_cv && <p className='error'>{errores.file_cv}</p>}
              </div>

              <div className="button-wrapper">
                <span className="label">
                  {" "}
                  <img
                    src={
                      uploadFoto === null
                        ? foto
                        : uploadFoto === true && checkTrue
                    }
                    style={{
                      marginRight: "10px",
                      paddingTop: "5px",
                      width: "25px",
                    }}
                    alt="clip"
                  />
                  SUBIR FOTOGRAFÍA
                  <br />
                  <div className="pdf-form">JPG O PNG</div>
                </span>
                <Form.Control
                  className="cFile"
                  type="file"
                  onChange={(e) => onChangeFoto(e)}
                />
              </div>
            </Form.Row>
            <Form.Row>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formPass">
                <Form.Control
                  type="password"
                  placeholder="Escriba una nueva contraseña"
                  className="stylePlace stylePlacer"
                  name="password"
                  values={form.password}
                  onChange={handleChange}
                  style={{ borderColor: errors.password == true && "#ff0000" }}
                  autoComplete="off"
                  hidden
                />
              </Form.Group>
            </Form.Row>
            {loading === true ? (
              <LoaderChico />
            ) : success === false ? (
              <Form.Row>
                <button
                  className="Enviar animate__animated animate__fadeIn"
                  type="submit"
                >
                  <span className="label">ENVIAR</span>
                </button>
              </Form.Row>
            ) : (
              success === true && (
                <Form.Row>
                  <button className="EnviarSuccess animate__animated animate__fadeIn">
                    <span className="label">
                      Su candidatura ha sido recibida, gracias.
                    </span>
                  </button>
                </Form.Row>
              )
            )}
            {success === null && (
              <><Form.Row>
              <div className="error-container">
                <button className="EnviarError">
                  <span className="label">
                    Ocurrió un error al enviar los datos.
                    <br />
                    <br />
                    {msg}
                    <br />
                    <br />
                  </span>
                </button>
                <button 
                  className="cerrarError" 
                  onClick={closeError} 
                  aria-label="Cerrar"
                >
                  X
                </button>
              </div>
            </Form.Row>
            
              </>
            )}
            <br />
            <br />
            {alertError === true && (
              <div className="alert-info">
                <p>Si tienes problema para subir tu CV, puedes enviarlo a esta dirección.</p>
                <p><b>trabaja.connosotrosmx@decathlon.com</b></p>
              </div>
            )}

            <Form.Row>
              <div className="buttonStatus" onClick={handleShow}>
                <span className="">AVISO DE PRIVACIDAD</span>
              </div>
            </Form.Row>
            <Modal
              show={show}
              dialogClassName="wmodalresp"
              keyboard={true}
              onHide={handleClose}
            >
              <Modal.Header closeButton className="bgBlue">
                <div className=" container">
                  <div className="titBack text-center col-12 my-1 text-white ">
                    AVISO DE PRIVACIDAD
                  </div>
                </div>
              </Modal.Header>
              <Modal.Body style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                <div className="row justify-content-center">
                  <div className="col-10 text-justify">
                    <Row>
                      <Col>
                        <h4>Aviso de Privacidad</h4>
                        <br />
                        <p>
                        Artículos Deportivos Decathlon, S.A. de C.V. (DECATHLON), 
                        Av. Ejército Nacional Mexicano 826, Polanco, Polanco III Secc, Miguel Hidalgo, 
                        11540 Ciudad de México, Ciudad de México, es el responsable del tratamiento de 
                        los datos personales que nos proporcione, los cuales serán protegidos conforme 
                        a lo dispuesto por la Ley Federal de Protección de Datos Personales en Posesión 
                        de Particulares (LFPDPPP).
                        </p>
                        <br />
                        <h4>1. ¿Qué datos personales se recabarán y para qué finalidades? <br />
                            Datos que se recaban:</h4>
                        <br />
                        <p>
                        Nombre, Apellidos, Edad, Género (Hombre, Mujer, Otro), Localidad (Ciudad de la vacante en Postulación),
                        Nivel Estudios, Tipo de jornada de Interés (Disponibilidad de horario), Deportes favoritos y nivel de expertiz,
                        Establecimiento para trabajar (tienda o corporativo), Oficio de interés en contratación, Contacto de teléfono celular,
                        contacto de correo electrónico, medio de contacto preferido para recibir información de la vacante,
                        curriculum (requerido) y fotografía (opcional).
                        </p>
                        <br />
                        <h4>Sus datos personales serán utilizados para las siguientes finalidades:</h4>
                        <br />
                        <p>
                        Estos datos sirven para que el reclutador relacione el perfil del candidato con vacante de interés 
                        y se evalúen las competencias, experiencia y disponibilidad.
                          <br />
                          El reclutador toma en cuenta las preferencias de contacto para que el candidato sea contactado 
                          si su perfil cumple con los requisitos generales de la vacante.
                          <br />
                          El candidato tiene la opción de elegir si desea ser contactado por email, whatsapp, o llamada telefónica.
                          <br />
                          En caso de que el candidato sea seleccionado para participar en una entrevista sus datos serán consultados 
                          para la preparación y seguimiento del proceso de entrevistas.
                          <br />
                          Asimismo, sus datos personales serán utilizados para generar un informe estadístico sobre los resultados 
                          de la gestión de reclutamiento.
                        </p>
                        <br />
                        <h4>2. Medios y/o fuentes de obtención de los datos personales.</h4>
                        <br />
                        <p>
                          <Container>
                            <Row>
                              <Col>
                                https://trabajoen.decathlon.com.mx/#/
                              </Col>
                              <Col>
                                Nombre de persona física<br />
                                Edad<br />
                                Género<br />
                                Localidad de la postulación<br />
                                Correo electrónico<br />
                                Datos de identificación<br />
                                Nivel de estudios<br />
                                Tipo de jornada de Interés (Disponibilidad de horario)<br />
                                Deportes favoritos y nivel de expertiz<br />
                                Establecimiento para trabajar (tienda o corporativo)<br />
                                Oficio de interés en contratación<br />
                                Contacto de teléfono celular<br />
                                Contacto de correo electrónico<br />
                                Medio de contacto preferido para recibir información de la vacante<br />
                                Curriculum (requerido PDF)<br />
                                Fotografía (opcional jpg, png)
                              </Col>
                            </Row>
                          </Container>
                        </p>
                      
                        <br />
                        <h4>3. Transferencias de datos personales.</h4>
                        <br />
                        <p>
                          DECATHLON, no realizará transferencias de datos personales, 
                          salvo aquéllas que sean necesarias para atender requerimientos de información de una autoridad competente, 
                          que estén debidamente fundados y motivados, o bien se actualice alguno de los supuestos señalados en el 
                          artículo 10 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, 
                          en las que no se requerirá de su conocimiento.
                        </p>
                        <h4>4. Fundamento para el tratamiento de sus datos personales.</h4>
                        <br />
                        <p>
                          DECATHLON trata los datos personales antes señalados con fundamento en el artículo 17 de la Ley Federal 
                          de Protección de Datos Personales en Posesión de los Particulares.
                        </p>
                        <br />
                        <h4>5. ¿Dónde puede ejercer los derechos de acceso, rectificación, cancelación, oposición y portabilidad de datos personales?</h4>
                        <br />
                        <p>
                        La plataforma no crea usuario y contraseña para el Titular de los Datos personales.<br />
                        En caso de que un Titular desee podrá ejercer sus derechos de Acceso, Rectificación, 
                        Cancelación, Oposición y Portabilidad de sus datos personales directamente al usuario trabaja.connosotrosmx@decathlon.com<br />
                        Presencialmente o a través de escrito presentando en el Módulo de atención de la Unidad de Transparencia ubicado en Av. 
                        Ejército Nacional Mexicano 826, Polanco, Polanco III Secc, Miguel Hidalgo, 11540 Ciudad de México, Ciudad de México.<br />
                        Por correo electrónico, a la cuenta: trabaja.connosotrosmx@decathlon.com.
                        </p>
                        <br />
                        <h4>
                        6. Sitio donde podrá consultar el aviso de privacidad integral y medios para comunicar a los titulares de los datos personales los cambios realizados al aviso de privacidad.
                        </h4>
                        <br />
                        <p>
                        El presente aviso de privacidad puede sufrir modificaciones, 
                        cambios o actualizaciones derivadas de nuevos requerimientos legales o por otras causas. 
                        Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, 
                        en el apartado denominado Avisos de Privacidad de Decathlon, a través de la página de internet https://trabajoen.decathlon.com.mx/#/.
                        </p>
                        <h4>
                        7. Portabilidad.
                        </h4>
                        <br />
                        <p>
                        Decathlon actualmente no realiza el tratamiento de datos personales en formatos estructurados y 
                        comúnmente utilizados para la portabilidad de datos personales.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </Form>
        )}
      </Container>
      {redirect === true && <Redirect to="/" />}
    </>
  );
};

export default Formulario;
