import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FormNext } from "grommet-icons";
import Select, { components } from "react-select";
import { useFetch } from "../hooks/useFetch";
import Map from "../components/Map";
import { BsPaperclip } from "react-icons/bs";
import { BACKEND } from "../components/backend";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ScrollBubble from './ScrollBubble';
  
const Team = ({ match }) => {
  const location = useLocation(); 
  const stateObj = location.state; 
  const [datx, setDatx] = useState([]);
  const [qPosition, setQposition] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [centerPosition, setCenterPosition] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(5);
  const [filteredSelPos, setFilteredSelPos] = useState([]);
  const [allData, setAllData] = useState([]);
  const [allPositions, setAllPositions] = useState([]);
  const [store, setStore] = useState(null); 
  var storeId = match ? match.params.store_id : undefined;
  const url = storeId ? `${BACKEND}api/jobs-list/${storeId}` : `${BACKEND}api/jobs-list/all`;
  const pathVacante = storeId ? "../" : "";

  const { data } = useFetch(url);

  useEffect(() => {
    if (data && Array.isArray(data.data)) {
      const rawData = data.data;
      setAllData(rawData); 
      const filteredStores = rawData.reduce((res, value) => {
        if (!res[value.store_location]) {
          res[value.store_location] = {
            value: value.store_location,
            label: value.store_id,
            qty: 0,
            lat: value.store_lat,
            lng: value.store_long,
          };
          res.push(res[value.store_location]);
        }
        res[value.store_location].qty += value.job_active;
        return res;
      }, []);

      localStorage.setItem("filteredStores", JSON.stringify(filteredStores));

      setMarkers(rawData.map(vac => ({
        lat: vac.store_lat,
        lng: vac.store_long,
        qty: vac.job_active,
        location: vac.store_location,
        store: vac.store_description
      })));

      const positions = rawData.map(vac => ({
        id: vac.job_name,
        label: vac.job_name,
        value: vac.job_name,
        location: vac.store_location,
        store: vac.store_description
      }));
      setAllPositions(positions);
    }
  }, [data]);

  useEffect(() => {
    let filteredData = allData;

    if (selectedLocation) {
      filteredData = filteredData.filter(vac => vac.store_location === selectedLocation.value);
    }

    if (qPosition) {
      filteredData = filteredData.filter(vac => vac.job_name === qPosition.label);
    }

    setDatx(filteredData);

    const positionsForLocation = allPositions.filter(pos => pos.location === selectedLocation?.value);
    setFilteredSelPos(positionsForLocation);

  }, [selectedLocation, qPosition, allData, allPositions]);

  useEffect(() => {
    if(stateObj == true){
      document.querySelector('.dropdown-menu').classList.add('show');
      window.scroll({
          top: 5360,
          left: 100,
          behavior: "smooth",
      });
    }
  })
  

  const handleChangeLocation = (val) => {
    setSelectedLocation(val);
    setCenterPosition([val.lat, val.lng]);
    setZoomLevel(13);

    setQposition(null);
  };

  const handleChangePosition = (selectedOption) => {
    setQposition(selectedOption);
  };

  const handleMarkerClick = (marker) => {
    const storeLocation = marker.location;
    const storename = marker.store;

    const filteredStores = allData.filter(vac => vac.store_description === storename);
    setSelectedLocation({ value: storeLocation, label: storeLocation });
    setStore(storename); 
    
    setDatx(filteredStores);

    setCenterPosition([marker.lat, marker.lng]);
    setZoomLevel(9);

    const positionsForStore = allPositions.filter(pos => pos.store === storename);
    setFilteredSelPos(positionsForStore); 
  };

  const handleMapClick = (latlng) => {
    setCenterPosition([latlng.lat, latlng.lng]);
    setZoomLevel(7);
  };

  const handleReset = () => {
    setSelectedLocation(null);
    setQposition(null);

    setCenterPosition([19.4311768, -99.2047097]);
    setZoomLevel(5);
  };

  const { Option } = components;
  const CustomSelectOption = (props) => <Option {...props}>{props.data.value}</Option>;
  const CustomSelectValue = (props) => <div>{props.data.value}</div>;

  return (
    <div id="team_map">
      <div className="team-map">
        <div className="title">
          <p>ENCUENTRA TU LUGAR</p>
          <p>EN DECATHLON</p>
        </div>
        <Row>
          <Col xs={12} md={12} style={{padding:'0'}} className="map_ini">
            <Select
              key={`location-${selectedLocation?.value}`}
              options={JSON.parse(localStorage.getItem("filteredStores")) || []}
              value={selectedLocation}
              components={{
                Option: CustomSelectOption,
                SingleValue: CustomSelectValue,
              }}
              placeholder="Ubicación"
              onChange={handleChangeLocation}
              name="ubicacion"
              className="select"
            />
            <Select
              key={`position-${qPosition?.value || 'default'}`} 
              value={qPosition}
              options={filteredSelPos}
              noOptionsMessage={() => 'Elige una posición'}
              placeholder="Posición"
              onChange={handleChangePosition}
              name="posicion"
              className="select"
            />
          </Col>
        </Row>
        <div className="row mt-3 contain">
          <div className="col-md-5 h-maps d-none d-lg-block d-md-block d-xl-block p-0">
            <Map 
              markerPosition={markers} 
              onMarkerClick={handleMarkerClick} 
              centerPosition={centerPosition} 
              zoomLevel={zoomLevel}
              onMapClick={handleMapClick} 
              onReset={handleReset} 
            />
          </div>
          <div className="col-md-7 ml--2">
            <div className="scroll">
              {datx.length === 0
                ? allData.map(vacantes => (
                    <div className="row pl-md-3 my-3 py-3 cardsVacantes text-justify pb-2" key={vacantes.job_id}>
                      <div className="col-12 col-md-8 col-sm-7">
                        <p className="tit-vacante">{vacantes.job_name}</p>
                        <h6>
                          {vacantes.job_duration === "Permanente" ? "(Contrato Indeterminado " : "(Contrato Temporal "}
                          {"/ " + vacantes.schedule + ")"}
                        </h6>
                        <span className="desc-vacante spacePre d-none">
                          {vacantes.job_description_project}
                        </span>
                      </div>
                      <div className="ml-auto col-12 col-md-4 col-sm-5 align-items-end text-center my-auto">
                        <Link className="btn-ver" to={`./${pathVacante}desc_ofert/${vacantes.job_id}`}>
                          Ver más<FormNext color="white" />
                        </Link>
                      </div>
                    </div>
                  ))
                : datx.map(vacantes => (
                    <div className="row pl-md-3 my-3 py-3 cardsVacantes text-justify pb-2" key={vacantes.job_id}>
                      <div className="col-12 col-md-8 col-sm-7">
                        <p className="tit-vacante">{vacantes.job_name}</p>
                        <h6>
                          {vacantes.job_duration === "Permanente" ? "(Contrato Indeterminado " : "(Contrato Temporal "}
                          {"/ " + vacantes.schedule + ")"}
                        </h6>
                        <span className="desc-vacante d-none">
                          {vacantes.job_description_project}
                        </span>
                      </div>
                      <div className="ml-auto col-12 col-md-4 col-sm-5 align-items-end text-center my-auto">
                        <Link className="btn-ver" to={`./${pathVacante}desc_ofert/${vacantes.job_id}`}>
                          Ver más<FormNext color="white" />
                        </Link>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </div>
        <div className="row justify-content-center mt-5">
          <div className="col-md-12 col-10 text-center no-vacante">
            ¿No encontraste la vacante que buscabas?
          </div>
          <br /> <br />
          <div className="col-10 col-md-8 col-lg-6 btn-vacante text-center">
            <Link to="/perfil/" className="text-center">
              <span className="btn-vacante-text">
                <BsPaperclip />
                &nbsp; Deja aquí tu CV para aplicar a futuras ofertas
              </span>
            </Link>
          </div>
        </div> 
        <ScrollBubble />
      </div>
    </div>
  );
};

export default Team;
