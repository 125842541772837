import React, { useEffect } from "react";
import { useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { AuthContext } from "../../../auth/AuthContext";
import { types } from "../../../types/types";

const Logger = ({ history }) => {
  const { dispatch } = useContext(AuthContext);
  const [showModal, setShowModal] = React.useState(false);
  let { search } = useLocation();
  let query = new URLSearchParams(search);
  let authorization = query.get("authorization");
  let refresh = query.get("refresh");

  useEffect(() => {
    if (authorization !== null) {
      localStorage.setItem('token', authorization);
      localStorage.setItem('refresh', refresh);
      history.replace("/cms/auth/admin-vacantes");
    } else {
      dispatch({
        type: types.adminlogout,
      });
      localStorage.clear();
      history.replace({
        pathname: '/team',
        search: '',
        state: true  
      });
      
      setShowModal(true);
    }
  }, [authorization, refresh, dispatch, history]);

  return (
    <>

    </>
  );
};

export default Logger;
