import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import header1 from "../styles/foto_header_desktop.jpg";
import flecha from "../styles/flecha.png";
import pasto from "../styles/textura_accesible.jpg";
import sust1 from "../styles/icon-sustentabilidad-1.svg";
import sust2 from "../styles/icon-sustentabilidad-2.svg";
import sust3 from "../styles/icon-sustentabilidad-3.svg";
import backInn from "../styles/back-innovacion_mobile.jpg";
import diversidad from "../styles/img-diversidad_mobile.png";
import Team from "./Team";



function QuienesSomos() {
  const [showModal, setShowModal] = useState(false);
const [isDrop, setIsDrop] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setShowModal(false);
    setIsDrop(false);
  }, []);

 
    return (
        <>
        <section className='quienes_somos'>
          <Row className='qs_mobile'>
            <Col xs={12} md={6} style={{padding:'0'}} ><img className="" src={header1} />  </Col>
            <Col xs={12} md={6} style={{padding:'0'}}>
              <div className='qs_header'>
                <h1>QUIÉNES <span className="green">SOMOS</span></h1>
                <br />
                <br />
                <p>En Decathlon, clientes y colaboradores tenemos una pasión en común: el deporte y su accesibilidad a todos</p>
                <br />
                <p>Somos un equipo de deportistas dinámicos y entusiastas, que comparten un mismo propósito: </p>
                <br />
                <br />
                <p></p>
                <p className='green'><b>Move people through the wonders of sport</b></p>
              </div>
            </Col>
          </Row>
        </section>
        <section className='accesible'>
          <Row>
            <Col xs={12} md={6} className='acc-col'>
              <p>¿QUÉ HACEMOS PARA </p>
              <p className='azul' style={{marginLeft:'10%'}}>HACER EL DEPORTE ACCESIBLE?</p>
              
              <p className='title'>NUESTRA MISIÓN COMO EMPRESA</p>
              <p>«Move people through the wonders of sport» y para ello, uno de los caminos que hemos elegido es hacer el placer y los beneficios del deporte accesibles al mayor número de personas.</p>
              <p className='title'>¿QUÉ ES LA ACCESIBILIDAD?</p>
              <p>Nos referimos a acercar nuestras tiendas físicas, digitales y diferentes canales de venta a los usuarios y también hacerlo a un precio que permita a cualquier persona iniciarse en un deporte. </p>
              <p className='title'>EL DEPORTE COMO VECTOR DE LA INTEGRACIÓN </p>
              <p>Creemos en el deporte como vector de integración, es por eso que trabajamos diferentes proyectos sociales con colectivos de vulnerabilidad bajo nuestra "Fundación Decathlon".</p>
            </Col>
            <Col xs={12} md={6} >
              <img className="back-pasto" src={pasto} /> 
              <iframe src="https://www.youtube.com/embed/tY63qaEY2SQ?si=lyvcHA-LYogv3brX" 
                      title="YouTube video player" 
                      frameBorder="0" 
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                      referrerPolicy="strict-origin-when-cross-origin" 
                      allowFullScreen
                      className='video'>
              </iframe>
            </Col>
          </Row>
        </section>
        <Row>
            <Col xs={12} md={12} style={{padding:'0'}}>
              <div className='sustentabilidad'>
                <p>DECATHLON POR</p>
                <p>LA SUSTENTABILIDAD</p>
              </div>
            </Col>
          </Row>
        <section className='sustentabilidad-back'>
          <Row className='susten'>
            <Col xs={12} md={7}>
            <iframe src="https://www.youtube.com/embed/qMoH5MVPONs?si=xHgmmPns38EFZYLs" 
                    title="YouTube video player" 
                    frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    referrerPolicy="strict-origin-when-cross-origin" 
                    allowFullScreen
                    className='video-sust'></iframe>
            </Col>
            <Col xs={12} md={5} className='desc'>
              <p className='title'>Diseñamos pensando</p>
              <p className='title tit'> en el medio ambiente</p>
              <p>En Decathlon, somos deportistas responsables con el cuidado de nuestro planeta y entendemos el medio natural como un escenario esencial para la práctica deportiva.</p>
              <p>Por eso, actuamos para proteger nuestro terreno de juego y generar un impacto positivo.</p>
              <p>Desde el eco-diseño y la producción de nuestras materias primas hasta el final de la vida útil de nuestros productos y nuestras estrategias de segunda vida.</p>
              <p>Pretendemos construir una comunidad para hacer llegar el deporte al mayor número de personas y así contribuir con una sociedad más saludable y sustentable.</p>
            </Col>
          </Row>
          <Row className='img-desktop'>
            <Col xs={12} md={12} className='sust-img'>
            <div>
              <img className="" src={sust1} />
              <p>Menor consumo de agua y energía</p> 
              </div>
            <div>
              <img className="" src={sust2} /> 
              <p>Materiales menos contaminantes</p>
              </div>
              <div className=''>
                <img className="" src={sust3} /> 
                <p>Materias primas recicladas</p>
              </div>
            </Col>
          </Row>
          <Row className='img-mobile'>
            <Col xs={12} md={12} className='sust-img'>
              <div>
                <img className="" src={sust1} />
                <p>Menor consumo de agua y energía</p> 
                </div>
              <div>
                <img className="" src={sust2} /> 
                <p>Materiales menos contaminantes</p>
              </div>
            </Col>
            
          </Row>
          <Row className='img-mobile'>
            <Col xs={4}>
              <div></div>
            </Col>
            <Col xs={4} md={12} className='col-img'>
              <img  src={sust3} /> 
              <p style={{textAlign:'center',width: '116%'}}>Materias primas recicladas</p>
            </Col>
            <Col xs={4}>
              <div></div>
            </Col>
          </Row>
        </section>
        <section className='innova'>
          <Row>
            <Col xs={12} md={12} style={{padding:'0'}}>
              <img src={backInn} className='mobile-inn'/>
              <div className='back-inno'>
                <p>INNOVACIÓN, PARTE</p>
                <p>DE NUESTRO ADN</p>
              </div>
            </Col>
          </Row>
          <Row className='video-innova'>
            <Col xs={12} md={7} style={{padding:'0'}}>
              <div className='back-gray'>
                <p>Vivimos en un entorno en constante cambio y las necesidades de los deportistas no son una excepción.</p>
                <p>En Decathlon innovamos para adaptarnos a las necesidades de nuestros usuarios.</p>
                <p>La innovación forma parte de nuestro ADN. Desde el diseño, hasta la venta en tienda y en línea, pasando por producción y logística.</p>
              </div>
            </Col>
            <Col xs={12} md={5} style={{padding:'0'}}>
            <iframe src="https://www.youtube.com/embed/Zp8yR-zZcTY?si=6WrYGqjXhmnDikZL" 
                    title="YouTube video player" 
                    frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    referrerPolicy="strict-origin-when-cross-origin" 
                    allowFullScreen
                    className=''></iframe>
            </Col>
          </Row>
        </section>
        <section>
          <Row className='div-row'>
            <Col xs={12} md={8} style={{padding:'0'}}>
              <div className='diversidad'>
                <div className='title'>
                  <p>DIVERSIDAD</p> 
                  <p>EQUIDAD E INCLUSIÓN</p>
                </div>
                <div>
                  <p>En Decathlon, la diversidad, equidad e inclusión son fundamentales para nuestra misión de hacer el deporte accesible para todos.</p>
                  <p>Estamos comprometidos a construir un entorno donde cada persona se sienta segura, respetada, valorada por lo que es y con el derecho de ser auténtico. Sin embargo, reconocemos que aún tenemos un camino por avanzar.</p>
                  <p>Contamos contigo para que juntos sigamos fortaleciendo una cultura inclusiva, donde cada voz cuente y donde todos podamos brillar sin dejar de ser quiénes somos.</p>
                </div>
              </div>
            </Col>
            <Col xs={12} md={4} className="text-center-back" style={{padding:'0'}}>
              <div className='title'>
                <p>DIVERSIDAD</p>
                <p>EQUIDAD E INCLUSIÓN</p>
              </div>
              <div className='img-div'>
                <img src={diversidad} alt="Diversidad" />
              </div>
            </Col>
          </Row>
        </section>
        <section>
          <Team />
        </section>

        </>
    )
}

export default QuienesSomos;

