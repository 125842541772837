import React, { useEffect, useState } from "react";
import { Filter, FormNext, FormDown, Dashboard } from "grommet-icons";
import axios from "axios";

import moment from "moment";
import SubNavBack from "./subNavBack";
import { useShowList } from "../../../services/getShowStoreListB";
import { AiOutlineEdit } from "react-icons/ai";
import { AiOutlineDelete } from "react-icons/ai";
import { FaLockOpen } from "react-icons/fa";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/js/bootstrap.js";
import "react-datepicker/dist/react-datepicker.css";
import SelectListStore from "./selectListStore";
import SelectListJobs from "./selectListJobs";
import SelectListDispo from "./selectListDispo";
import SelectListSports from "./selectListSports";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "moment/locale/es";
import SelectListCalendar from "./selectListCalendar";
import { Loader } from "../../Loader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { LoaderDel } from "../../LoaderDel";
import { Link } from "react-router-dom";
import { LoaderChico } from "../../LoaderChico";
import { BACKEND } from "../../backend";
import Table from 'react-bootstrap/Table';

registerLocale("es", es);
setDefaultLocale("es");
const Dashboardh = () => {
  const [show, setShow] = useState(false);
  const [showDel         , setShowDel] = useState(false);
  const [pre, setPre] = useState(false);
  const [load, setLoad] = useState(false);
  // Modales states
  const [calendario, setCalendario] = useState(false);
  const [calendarioOpt, setCalendarioOpt] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showTitle, setShowTitle] = useState("");
  const [job, setJob] = useState("");
  const [store, setStore] = useState("");
  const [del, setDel] = useState();
  const [ opt, setOpt ] = useState([]);
  const [ dashboard, setDashboard ] = useState([]);
  const [ optBack, setOptBack ] = useState([]);
  //const [ dashboardBack, setDashboardBack ] = useState([]);
  const [ search, setSearch ] = useState(null);
  const [modalshow, setModalshow] = useState(null);
  const [oportunidades, setOportunidades] = useState({
    messages: `Hola,  Ante todo, gracias por tu interés en trabajar en Decathlon y por el tiempo que has dedicado a este proceso de selección. Este mail es para informarte de que tu perfil no se ajusta completamente a esta oferta pero… No te rindas! Hemos dejado tu candidatura en nuestra base de datos para que tengas nuevas oportunidades. Un saludo!`,
  });
  /*
  useEffect(() => { // fix para forzar actualizacion de mensaje
    if(modalshow === 1){
        let message = document.getElementById('txa-oportunidades').value;
        setOportunidades({ ...gracias, messages: message });
    }
  }, [modalshow]);
  const onChangeOportunidades = (e) => {
    setOportunidades({ ...oportunidades, messages: e.target.value });
  };
*/
  // ERRORES
  const [errors, setErrors] = useState({
    store_id: "",
    charge_id: "",
  });
  // Estados  de la respuesta
  const [res, setRes] = useState(null);
  const [resDel, setResDel] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const baseUrl = BACKEND + "api/cms/jobs/update";
  const baseUrlDel = BACKEND + "api/cms/jobs/";
  const baseUrlRemove = BACKEND + "api/applicant/remove-apply";


  const [sportName, setSportName] = useState(null);
  const [showSportName, setShowSportName] = useState(false);



    const setUserStorage = async () => {
        try {
            const response = await axios({
                url: BACKEND + "api/common/profile",
                method: "GET",
                headers: { Authorization: "Bearer " + token },
            });
            localStorage.setItem('userStorage', JSON.stringify(response.data));
        } catch (e) {
            console.log("error setUserStorage");
        }
    }

    useEffect(() => {
        let userStorage = localStorage.getItem('userStorage');
        if(!userStorage){
            setUserStorage();
        }
    }, []);

  useEffect(() => {
    // setShowTitle(job + store);
    setForm({ ...form, name: showTitle });

    if(pre?.charge_id === 1 && sportName){ // only in Lider deporte = 1
        let newTitle = `${pre.charge} ${sportName} en ${pre.store}`;
        setShowTitle(newTitle);
    }else{
        setShowTitle(job + store);
    }

  }, [job, store, showTitle, sportName]);
  const [form, setForm] = useState({
    id: "",
    name: "",
    description_project: "",
    description_responsibilities: "",
    description_who_you_are: "",
    store_id: "",
    charge_id: "",
    schedule_id: "",
    duration_period: "",
    sport_id: "",
    // image: "",
  });
  // Datos de los Jobs


    const fetchVacancies = async () => {
        setLoad(true);
        await axios({
            url: `${BACKEND}api/cms/jobs-applicants`,
            method: "GET",
            headers: { Authorization: "Bearer " + token },
        })
        .then((response) => {
            setOpt(response.data.data);
            setOptBack(response.data.data);
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoad(false);
        })
    };

    useEffect(() => {
        fetchVacancies();
    }, []);

    const fetchDashboard = async () => {
      setLoad(true);
      await axios({
          url: `${BACKEND}api/cms/jobs-dashboard`,
          method: "GET",
          headers: { Authorization: "Bearer " + token },
      })
      .then((response) => {
          setDashboard(response.data.data);
      })
      .catch((error) => {
          console.log(error);
      })
      .finally(() => {
          setLoad(false);
      })
  };

  useEffect(() => {
      fetchDashboard();
  }, []);

    const orderBy = key => {
        let copyOpt = [ ...opt ];
        copyOpt = copyOpt.sort((a, b) => (a[key] > b[key]) ? 1 : -1);
        setOpt(copyOpt);
    }

    const handleSearch = value => {
        let copyOpt = [ ...optBack ];
        if(value){
            let lowercasedValue = value.toLowerCase().trim();
            console.log("lower--");
            console.log(lowercasedValue);
            let filterData = copyOpt.filter(item => {
                return (
                    item.job_name.toString().toLowerCase().includes(lowercasedValue) ||
                    item.store_name.toString().toLowerCase().includes(lowercasedValue) ||
                    item.applicants.some(o => o.applicant_data.full_name.toString().toLowerCase().includes(lowercasedValue))
                )
            });
            console.log("filter--");
            console.log(filterData);
            setOpt(filterData);
        }else {
            setOpt(optBack);
        }
    }

  // Modal

  const handleClose = () => {
    setShow(false);
    setShowDel(false);
    setSportName(null);
  };
  const handleShow = (vac) => {
    if (vac.job_duration === "Determinado") {
      setCalendarioOpt("Determinado");
      setCalendario(false);
      setForm({ ...form, duration_period: "Determinado" });
    }
    if (vac.job_duration !== "Determinado") {
      setCalendarioOpt("Indeterminado");
      setCalendario(true);
      let datos = vac.job_duration.split(",");
      setStartDate(moment(datos[0]).toDate());
      setEndDate(moment(datos[1]).toDate());
    }
    setShow(true);
    setJob(vac.job_name);
    setPre({
      id: vac.job_id,
      name: "",
      description_project: vac.job_description_project,
      description_responsibilities: vac.job_description_responsibilities,
      description_who_you_are: vac.job_description_who_you_are,
      store_id: vac.store_id,
      sport_description: vac.sport,
      charge_id: vac.charge_id,
      schedule_id: vac.schedule_id,
      duration_period: vac.job_duration,
      sport_id: vac.sport_id,
      charge: vac.charge,
      store: vac.store_name,
    });
    setForm({
      id: vac.job_id,
      name: job,
      description_project: vac.job_description_project,
      description_responsibilities: vac.job_description_responsibilities,
      description_who_you_are: vac.job_description_who_you_are,
      store_id: vac.store_id,
      // sport_description: vac.sport_description,
      charge_id: vac.charge_id,
      schedule_id: vac.schedule_id,
      duration_period: vac.job_duration,
      sport_id: vac.sport_id,
    });
  };
  useEffect(() => {
    if (pre.duration_period === "Determinado") {
      setCalendarioOpt("Determinado");
      setForm({ ...form, duration_period: "Determinado" });
    }
    if (pre.duration_period !== "Determinado") {
      setCalendarioOpt("Indeterminado");
      setCalendario(true);
    }
  }, []);
  const handleDelete = (vac) => {
    setShowDel(true);
    setDel(vac.job_id);
    console.log("Var: api/cms/jobs/" + vac.job_id );
  };

  // Modal body Form para actualizar

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setForm({ ...form, duration_period: start + ", " + end });
  };
  const onChangeStore = (value) => {
    setJob("");
    setForm({ ...form, store_id: value.value });
    setStore(value.label);
    setErrors({ ...errors, store_id: false });
  };
  const onChangeJob = (value) => {
    setForm({ ...form, charge_id: value.value });
    setJob(value.label + " en ");
    setErrors({ ...errors, charge_id: false });
  };
  const onChangeSchedule = (value) => {
    setForm({ ...form, schedule_id: value.value });
  };
  const onChangeSport = (id) => {
    setForm({ ...form, sport_id: id });
  };
  const onChangeProj = (e) => {
    setForm({ ...form, description_project: e.target.value });
  };
  const onChangeRespo = (e) => {
    setForm({ ...form, description_responsibilities: e.target.value });
  };
  const onChangeWho = (e) => {
    setForm({ ...form, description_who_you_are: e.target.value });
  };
  const onChangeCalendario = (value) => {
    if (value.value === true) {
      setCalendario(value.value);
    } else if (value.value === false) {
      setForm({ ...form, duration_period: "Determinado" });
      setCalendario(value.value);
    }
  };
  const Validacion = () => {
      setLoading(true);
      fetchData();
      setLoading(false);
  };

  const fetchData = async () => {
    try {
        setLoad(true);
      const resp = await axios({
        url: baseUrl,
        method: "POST",
        headers: { Authorization: "Bearer " + token },
        data: form,
      });
      Load(resp.data);
      setRes(resp);
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setShow(false);
      }, 2500);
      setError(null);
    } catch (error) {
      setError(error);
    } finally {
      setTimeout(() => {
        setLoad(true);
        window.location.reload();
      }, 2500);
    }
  };

  const Load = (resDel) => {
    return <LoaderDel resultado={resDel} />;
  };

  const fetchArchive = async () => {
    try {
      const response = await axios({
        url: baseUrlDel + del,
        method: "DELETE",
        headers: { Authorization: "Bearer " + token },
      });
      Load(response.data);
      setResDel(response);
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setShowDel(false);
      }, 2500);
      setError(null);
    } catch (error) {
      Load(error.data);
      setResDel(error);
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setShowDel(false);
      }, 2500);
    } finally {
      setTimeout(() => {
        setLoading(false);
        window.location.reload();
      }, 2500);
    }
  };

  const fetchDelete = async () => {

    try {
      const response = await axios({
        url: BACKEND + "api/cms/jobs/delete/" + del,
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      });

      console.log("DELETE:" + BACKEND + "api/cms/jobs/delete/" + del);
      Load(response.data);
      setResDel(response);
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setShowDel(false);
      }, 2500);
      setError(null);
    } catch (error) {
      Load(error.data);
      setResDel(error);
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setShowDel(false);
      }, 2500);
    } finally {
      setTimeout(() => {
        setLoading(false);
        window.location.reload();
      }, 2500);
    }
  };


  const fetchReopen = async (job) => {
    toast.info('Espere, por favor', { position: "top-center" });
    try {
      const response = await axios({
        url: BACKEND + "api/cms/jobs/reopen/" + job.job_id,
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      });
      Load(response.data);
      setResDel(response);
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setShowDel(false);
      }, 2500);
      setError(null);
    } catch (error) {
      Load(error.data);
      setResDel(error);
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setShowDel(false);
      }, 2500);
    } finally {
      setTimeout(() => {
        setLoading(false);
        window.location.reload();
      }, 2500);
    }
  };


  let termino, term;

  return (
    <>
      <SubNavBack />
      <ToastContainer />
      <div className="container-fluid  bg-back">
        <div className="row justify-content-center">
          <div className="col-10  bg-white bgShadow mb-3 animate__animated animate__fadeIn">
            <div className="row  justify-content-center">
              <div className="titBack text-left col-6 my-md-5 pt-md-5">
                Soporte
              </div>
            {/* </div> */}
              <div className="row  mb-3 col-10 ">
                <Table responsive striped bordered hover className="center">
                  <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>Correo</th>
                      <th>No. Telefonico</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                    <td>                
                      Jason García
                      </td>
                      <td>                
                        jason.garcia@decathlon.com
                      </td>
                      <td>                
                        -
                      </td>
                    </tr>
                    <tr>
                    <td>                
                      Lucien Norga
                      </td>
                      <td>                
                        lucien.norga@decathlon.com
                      </td>
                      <td>                
                        -
                      </td>
                    </tr>

                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboardh;