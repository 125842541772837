import React, { useEffect, useState } from "react";
import axios from "axios";
import SubNavBack from "./subNavBack";
import "bootstrap/dist/js/bootstrap.js";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import "moment/locale/es";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BACKEND } from "../../backend";
import Table from 'react-bootstrap/Table';
import { AgChartsReact } from 'ag-charts-react';
import {
  Container,
  Col,
  Card
} from "react-bootstrap";
import CustomSelect from '../baseDatosCv/CustomSelect';
import { Modal, Button } from "react-bootstrap";
import { LoaderChico } from "../../LoaderChico";




const Dashboardh = () => {

  const [load, setLoad] = useState(false);
  const [ dashboard, setDashboard ] = useState([]);
  const [ storesStatus, setStoresStatus ] = useState([]);
  const [ storesStatusInterior, setStoresStatusInterior ] = useState([]);
  const [ storesStatusValle, setStoresStatusValle ] = useState([]);
  const [ storesCandidatos, setStoresCandidatos ] = useState([]);
  const [ storesContratacion, setStoresContratacion ] = useState([]);
  const [ storesPromContratar, setStoresPromContratar ] = useState([]);
  const [ storesPromContratarTratar, setStoresPromContratarTratar ] = useState([]);
  const [ storesDash, setStoresDash ] = useState([]);
  const [ storesSeguimiento, setStoresSeguimiento ] = useState([]);

  
  const [ stores, setStores ] = useState([]);
  // const [store, setStore] = useState({ label: "País", value: "54" });
  const [store, setStore] = useState(null);
  const [ storesValle, setStoresValle ] = useState([]);
  const [ storesInterior, setStoresInterior ] = useState([]);
  const [ storesPais, setStoresPais ] = useState([]);
  const [ storesPostulacion, setStoresPostulacion ] = useState([]);
  const [ storesPostulacion2, setStoresPostulacion2 ] = useState([]);
  const [ storesContratados, setStoresContratados ] = useState([]);
  const [ storesContratados2, setStoresContratados2 ] = useState([]);
  const [ storesPais2, setStoresPais2 ] = useState([]);
  const [ storesGender, setStoresGender ] = useState([]);
  const [ storeGenderM, setStoreGenderM ] = useState([]);
  const [ storeGenderH, setStoreGenderH ] = useState([]);
  const [ storeGenderM2, setStoreGenderM2 ] = useState([]);
  const [ storeGenderH2, setStoreGenderH2 ] = useState([]);
  const [show2, setShow2] = useState(true);
  const fecha = new Date();
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);

  const token = localStorage.getItem("token");

    const setUserStorage = async () => {
        try {
            const response = await axios({
                url: BACKEND + "api/common/profile",
                method: "GET",
                headers: { Authorization: "Bearer " + token },
            });
            localStorage.setItem('userStorage', JSON.stringify(response.data));
        } catch (e) {
            console.log("error setUserStorage");
        }
    }

    useEffect(() => {
        let userStorage = localStorage.getItem('userStorage');
        if(!userStorage){
            setUserStorage();
        }
    }, []);

    const fetchDashboard = async () => {
      var fecha = new Date('2024-10-01T00:00:00-06:00');
      var primerDiaAnio = new Date(fecha.getFullYear(), 0, 1);
      var fechaActual = new Date();
      fechaActual.setHours(fechaActual.getHours() - fechaActual.getTimezoneOffset() / 60 - 6);
  
      let start = (startDate === null) ? primerDiaAnio : startDate;
      let end = (endDate === null) ? fechaActual : endDate;
  
      await axios({
          url: `${BACKEND}api/cms/jobs-dashboard/`+ start.toISOString() + "_" + end.toISOString(),
          method: "GET",
          headers: { Authorization: "Bearer " + token },
      })
      .then((response) => {
          setDashboard(response.data.data);
          setStore({label: "País", value: "54"})
      })
      .catch((error) => {
          console.log(error);
      })
      
  };

  useEffect(() => {
      fetchDashboard();
  }, []);


  const fetchStoresStatusInterior = async () => {
    setLoad(true);

    var fecha = new Date('2024-10-01T00:00:00-06:00');
    var primerDiaAnio = new Date(fecha.getFullYear(), 0, 1);
    var fechaActual = new Date();
    fechaActual.setHours(fechaActual.getHours() - fechaActual.getTimezoneOffset() / 60 - 6);

    let start = (startDate === null) ? primerDiaAnio : startDate;
    let end = (endDate === null) ? fechaActual : endDate;

    await axios({
       url: `${BACKEND}api/cms/stores-status-interior/` + start.toISOString() + "_" + end.toISOString(),
        method: "GET",
        headers: { Authorization: "Bearer " + token },
    })
    .then((response) => {
      console.log("Response", response);
        setStoresStatusInterior(response.data.data);
    })
    .catch((error) => {
        console.log(error);
    })
    .finally(() => {
        setLoad(false);
    })
  };

  const fetchStoresStatusValle = async () => {
    setLoad(true);
    var fecha = new Date('2024-10-01T00:00:00-06:00');
    var primerDiaAnio = new Date(fecha.getFullYear(), 0, 1);
    var fechaActual = new Date();
    fechaActual.setHours(fechaActual.getHours() - fechaActual.getTimezoneOffset() / 60 - 6);

    let start = (startDate === null) ? primerDiaAnio : startDate;
    let end = (endDate === null) ? fechaActual : endDate;

    await axios({
      url: `${BACKEND}api/cms/stores-status-valle/` + start.toISOString() + "_" + end.toISOString(),
        method: "GET",
        headers: { Authorization: "Bearer " + token },
    })
    .then((response) => {
        setStoresStatusValle(response.data.data);
    })
    .catch((error) => {
        console.log(error);
    })
  

  };
  
  

  const fetchStoresCandidatos = async () => {
    setLoad(true);
    
    await axios({
        url: `${BACKEND}api/cms/pregresion-candidatura`,
        method: "GET",
        headers: { Authorization: "Bearer " + token },
    })
    .then((response) => {
        setStoresCandidatos(response.data.data);
    })
    .catch((error) => {
        console.log(error);
    })

  };

  const fetchStoresContratacion = async () => {
    setLoad(true);
    await axios({
        url: `${BACKEND}api/cms/pregresion-contratacion`,
        method: "GET",
        headers: { Authorization: "Bearer " + token },
    })
    .then((response) => {
        setStoresContratacion(response.data.data);
    })
    .catch((error) => {
        console.log(error);
    })

  };

  const fetchStoresGender = async () => {
    setLoad(true);
    await axios({
        url: `${BACKEND}api/cms/perfil-candidatos`,
        method: "GET",
        headers: { Authorization: "Bearer " + token },
    })
    .then((response) => {
        setStoresGender(response.data.data);
    })
    .catch((error) => {
        console.log(error);
    })

  };

  const fetchStoresPromContratar = async () => {
    setLoad(true);
    
    var fecha = new Date('2024-10-01T00:00:00-06:00');
    var primerDiaAnio = new Date(fecha.getFullYear(), 0, 1);
    var fechaActual = new Date();
    fechaActual.setHours(fechaActual.getHours() - fechaActual.getTimezoneOffset() / 60 - 6);

    let start = (startDate === null) ? primerDiaAnio : startDate;
    let end = (endDate === null) ? fechaActual : endDate;
    
    await axios({
      url: `${BACKEND}api/cms/dias-prom-contratar/`+ start.toISOString() + "_" + end.toISOString(),
        method: "GET",
        headers: { Authorization: "Bearer " + token },
    })
    .then((response) => {
        setStoresPromContratar(response.data.data);
    })
    .catch((error) => {
        console.log(error);
    })
    
  };

  useEffect(() => {
    fetchStoresStatus();
    fetchStoresStatusInterior(); //SI ok
    fetchStoresStatusValle(); //SI ok
    fetchStores(); //no
    fetchStoresCandidatos(); //no
    fetchStoresGender(); // no sirve
    fetchStoresContratacion(); //no
    fetchStoresPromContratar(); // SI esta
    
    setTimeout(() => {
      setStore({label: "País", value: "54"})
    }, 3300);
  }, []);

  const handleClose2 = () => {
    setShow2(false);
  };


  let myTheme = {
    baseTheme: "ag-default",
    palette: {
      fills: ["#a2cafa", "#60a1f0", "#3474c2", "#164d91","#001780"],
      strokes: ["black"],
    },
    overrides: {
      common: {
        title: {
          fontSize: 24,
        },
      },
      bar: {
        series: {
          label: {
            enabled: true,
            color: "black",
          },
        },
      },
    },
  };

  useEffect(() => {
    storesStatus.forEach(element => {
      if(store.label == ""){
        console.log("store___en pais________" );
        if(element.store == "Páis"){
          storesPais2[0] = { asset: "Pendientes", amount: element.job_pending };
          storesPais2[1] = { asset: "En proceso", amount: element.job_inprocess };
          storesPais2[2] = { asset: "Procesos finalizados", amount: element.job_finished };
          storesPais2[3] = { asset: "Contratados", amount: element.job_hired };
          
          setOptionsSegPais(
            {
              theme: myTheme,
              data: storesPais2,
              title: {
                text: "Seguimiento " + store.label,
              },
              series: [
                {
                  type: "pie",
                  angleKey: "amount",
                  // legendItemKey: "asset",
                  calloutLabelKey: "asset",
                  sectorLabelKey: "amount",
                  sectorLabel: {
                    color: "white",
                    fontWeight: "bold",
                    formatter: ({ value }) => `$${(value).toFixed(0)}`,
                  },
                  
                },
              ],
              legend: {
                enabled: false
              },
              background: {
                visible: false,
              }
            }
          );
        
        }

      }else{
        if(element.store == store.label){
          storesPais2[0] = { asset: "Pendientes", amount: element.job_pending };
          storesPais2[1] = { asset: "En proceso", amount: element.job_inprocess };
          storesPais2[2] = { asset: "Procesos finalizados", amount: element.job_finished };
          storesPais2[3] = { asset: "Contratados", amount: element.job_hired };
          
          setOptionsSegPais(
            {
              theme: myTheme,
              data: storesPais2,
              title: {
                text: "Seguimiento " + store.label,
              },
              series: [
                {
                  type: "pie",
                  angleKey: "amount",
                  // legendItemKey: "asset",
                  calloutLabelKey: "asset",
                  sectorLabelKey: "amount",
                  sectorLabel: {
                    color: "white",
                    fontWeight: "bold",
                    formatter: ({ value }) => `$${(value).toFixed(0)}`,
                  },
                 
                },
              ],
              legend: {
                enabled: false
              },
              background: {
                visible: false,
              }
            }
          );
        
        }
      }
    });
  
      storesGender.forEach(element => {
        if(store.length == 0){
          console.log("store___en pais________" );
          if(element.store == "Páis" && element.genero == "Mujer"){
            storeGenderM2[0] = { asset: "18-25", amount: element.a }
            storeGenderM2[1] = { asset: "26-30", amount: element.b }
            storeGenderM2[2] = { asset: "31-35", amount: element.c }
            storeGenderM2[3] = { asset: "36-40", amount: element.d }
            storeGenderM2[4] = { asset: "+40 años"  , amount: element.e }
            ;
          
          }
          if(element.store == "Páis" && element.genero == "Hombre"){
            storeGenderH2[0] = { asset: "18-25", amount: element.a }
            storeGenderH2[1] = { asset: "26-30", amount: element.b }
            storeGenderH2[2] = { asset: "31-35", amount: element.c }
            storeGenderH2[3] = { asset: "36-40", amount: element.d }
            storeGenderH2[4] = { asset: "+40 años"  , amount: element.e }
            ;
          
          }
        }else{
          console.log("store___en tienda________" );
          if(element.store == store.label && element.genero == "Mujer"){
            storeGenderM2[0] = { asset: "18-25", amount: element.a }
            storeGenderM2[1] = { asset: "26-30", amount: element.b }
            storeGenderM2[2] = { asset: "31-35", amount: element.c }
            storeGenderM2[3] = { asset: "36-40", amount: element.d }
            storeGenderM2[4] = { asset: "+40 años"  , amount: element.e }
            ;
          
          }
          if(element.store == store.label && element.genero == "Hombre"){
            storeGenderH2[0] = { asset: "18-25", amount: element.a }
            storeGenderH2[1] = { asset: "26-30", amount: element.b }
            storeGenderH2[2] = { asset: "31-35", amount: element.c }
            storeGenderH2[3] = { asset: "36-40", amount: element.d }
            storeGenderH2[4] = { asset: "+40 años"  , amount: element.e }
            ;
          
          }
        }
      });
      setOptionsPerfilM(
        {
          theme: myTheme,
          data: storeGenderM2,
          title: {
            text: "Perfil de mis candidatas",
          },
          series: [
            {
              type: "pie",
              angleKey: "amount",
              legendItemKey: "asset",
            },
          ],
        }
      );
      setOptionsPerfilH(
        {
          theme: myTheme,
          data: storeGenderH2,
          title: {
            text: "Perfil de mis candidatos",
          },
          series: [
            {
              type: "pie",
              angleKey: "amount",
              legendItemKey: "asset",
            },
          ],
        }
      );
  
      if (storesPromContratar && storesPromContratar.length > 0) {
        storesPromContratar.forEach(element => {
          if (element.store === store.label) {
            // Check if the values are not null before assigning
            if (element.diasPromedioContratar !== null && element.diasPromedioTratar !== null) {
              storesPromContratarTratar[0] = {
                diasPromedioContratar: element.diasPromedioContratar,
                diasPromedioTratar: element.diasPromedioTratar
              };
            }
          }
        });
      } else {
        console.log("storesPromContratar is null or empty.");
      }
  
    dashboard.forEach(element => {
      if(element.store == store.label){
        storesDash[0] = { vacantes: element.vacantes, offers: element.offers, dataB: element.dataB };
      }
    });
    storesStatus.forEach(element => {
      if(element.store == store.label){
        storesSeguimiento[0] = { seguimiento: (((element.job_finished + element.job_hired) / (element.job_finished + element.job_hired + element.job_inprocess + element.job_pending)) * 100).toFixed(2), job_pending : element.job_pending, job_inprocess : element.job_inprocess, job_finished : element.job_finished, job_hired : element.job_hired };
      }
    });
    
    contPostulacion = 0;
    storesCandidatos.forEach(element => {
      if(element.store == store.label){
        storesPostulacion2[contPostulacion] = { month: element.month, amount: element.amount };
        contPostulacion = contPostulacion + 1;
    
      }
    });
  
    setOptionSeg(
      {
        title: {
          text: "PROGRESIÓN DE POSTULACIÓN",
        },
        theme: myTheme,
        data: storesPostulacion2,
        series: [
          {
            type: "line",
            xKey: "month",
            yKey: "amount",
            yName: "Total de postulantes",
          },
        ],
      }
    );
  
    contPostulacion = 0;
    if(storesContratacion.length){
    storesContratacion.forEach(element => {
      if(element.store == store.label){
        storesContratados2[contPostulacion] = { month: element.month, amount: element.amount };
        contPostulacion = contPostulacion + 1;
      }
    });
  }
  
    setOptionSegContratar(
      {
        title: {
          text: "PROGRESIÓN DE CONTRATACIÓN",
        },
        theme: myTheme,
        data: storesContratados2,
        series: [
          {
            type: "line",
            xKey: "month",
            yKey: "amount",
            yName: "Total de contratados",
          },
        ],
      }
    );
  
    
  
  }, [store]);
  

  const formatForOptions = (data, keyValue, keyLabel) => {
    return data.map(entry => ({label: entry[keyLabel], value: entry[keyValue]}));
  }
  
  const fetchStores = async () => {
    await axios.get(`${BACKEND}api/store-list-dashboard`)
        .then((response) => {
            let formatingStores = formatForOptions(response.data.data, 'id', 'store_description');
            setStores(formatingStores);
            })
            .catch((error) => {
            console.log(error);
        });
};

let contValle = 0;
storesStatusValle.forEach(element => {
  storesValle[contValle] = { store: element.store, job_pending: element.job_pending, job_inprocess: element.job_inprocess, job_finished: element.job_finished, job_hired: element.job_hired } ;
  contValle = contValle + 1;
});
const [chartOptions, setChartOptions] = useState({
  data: storesValle
  ,
  series: [ { type: 'bar', strokeWidth: 35, xKey: 'store', yKey: 'job_pending', yName: 'Pendientes', stacked: true },
            { type: 'bar', strokeWidth: 35, xKey: 'store', yKey: 'job_inprocess', yName: 'En proceso', stacked: true },
            { type: 'bar', strokeWidth: 35, xKey: 'store', yKey: 'job_finished', yName: 'Procesos finalizados', stacked: true },
            { type: 'bar', strokeWidth: 35, xKey: 'store', yKey: 'job_hired', yName: 'Contratados', stacked: true }
],
});  

contValle = 0;
storesStatusInterior.forEach(element => {
  storesInterior[contValle] = { store: element.store, job_pending: element.job_pending, job_inprocess: element.job_inprocess, job_finished: element.job_finished, job_hired: element.job_hired } ;
  contValle = contValle + 1;
});

const [chartOptions3, setChartOptions3] = useState({
  data: storesInterior
  ,
  series: [ { type: 'bar', strokeWidth: 35, xKey: 'store', yKey: 'job_pending', yName: 'Pendientes', stacked: true },
            { type: 'bar', strokeWidth: 35, xKey: 'store', yKey: 'job_inprocess', yName: 'En proceso', stacked: true },
            { type: 'bar', strokeWidth: 35, xKey: 'store', yKey: 'job_finished', yName: 'Procesos finalizados', stacked: true },
            { type: 'bar', strokeWidth: 35, xKey: 'store', yKey: 'job_hired', yName: 'Contratados', stacked: true }
],
});

storesStatus.forEach(element => {
  if(element.store == "País"){
    storesPais[0] = { asset: "Pendientes", amount: element.job_pending }
    storesPais[1] = { asset: "En proceso", amount: element.job_inprocess }
    storesPais[2] = { asset: "Procesos finalizados", amount: element.job_finished }
    storesPais[3] = { asset: "Contratados", amount: element.job_hired }
    ;
  
  }
});

const [optionsSegPais, setOptionsSegPais] = useState({
  theme: myTheme,
  data: storesPais,
  title: {
    text: "Seguimiento País",
  },
  series: [
    {
      type: "pie",
      angleKey: "amount",
      // legendItemKey: "asset",
      calloutLabelKey: "asset",
      sectorLabelKey: "amount",
      sectorLabel: {
        color: "white",
        fontWeight: "bold",
        formatter: ({ value }) => `$${(value).toFixed(0)}`,
      },
      
    },
  ],
  legend: {
    enabled: false
  },
  background: {
    visible: false,
  }
});

storesGender.forEach(element => {
  if(element.store == "País" && element.genero == "Mujer"){
    storeGenderM[0] = { asset: "18-25", amount: element.a }
    storeGenderM[1] = { asset: "26-30", amount: element.b }
    storeGenderM[2] = { asset: "31-35", amount: element.c }
    storeGenderM[3] = { asset: "36-40", amount: element.d }
    storeGenderM[4] = { asset: "+40 años"  , amount: element.e }
    ;
  
  }
  if(element.store == "País" && element.genero == "Hombre"){
    storeGenderH[0] = { asset: "18-25", amount: element.a }
    storeGenderH[1] = { asset: "26-30", amount: element.b }
    storeGenderH[2] = { asset: "31-35", amount: element.c }
    storeGenderH[3] = { asset: "36-40", amount: element.d }
    storeGenderH[4] = { asset: "+40 años"  , amount: element.e }
    ;
  
  }
});

const [optionsPerfilM, setOptionsPerfilM] = useState({
  theme: myTheme,
  data: storeGenderM,
  title: {
    text: "Perfil de mis candidatas",
  },
  series: [
    {
      type: "pie",
      angleKey: "amount",
      legendItemKey: "asset",
    },
  ],
});
const [optionsPerfilH, setOptionsPerfilH] = useState({
  theme: myTheme,
  data: storeGenderH,
  title: {
    text: "Perfil de mis candidatos",
  },
  series: [
    {
      type: "pie",
      angleKey: "amount",
      legendItemKey: "asset",
    },
  ],
});

const uniqueStoresStatus = storesStatus.filter((store, index, self) =>
  index === self.findIndex((t) => (
    t.store === store.store
  ))
);

let contPostulacion = 0;
storesCandidatos.forEach(element => {
  if(element.store == "País"){
    storesPostulacion[contPostulacion] = { month: element.month, amount: element.amount };
    contPostulacion = contPostulacion + 1;
  }
});


let contContratacion = 0;
if(storesContratacion.length){
  storesContratacion.forEach(element => {
  if(element.store == "País"){
    storesContratados[contContratacion] = { month: element.month, amount: element.amount };
    contContratacion = contContratacion + 1;
  }
});
}


const [optionsSeg, setOptionSeg] = useState({
    title: {
      text: "PROGRESIÓN DE POSTULACIÓN",
    },
    theme: myTheme,
    data: storesPostulacion,
    series: [
      {
        type: "line",
        xKey: "month",
        yKey: "amount",
        yName: "Total de postulantes",
      },
    ],
  });

  const [optionsSegContratar, setOptionSegContratar] = useState({
    title: {
      text: "PROGRESIÓN DE CONTRATACIÓN",
    },
    theme: myTheme,
    data: storesContratados,
    series: [
      {
        type: "line",
        xKey: "month",
        yKey: "amount",
        yName: "Total de contratados",
      },
    ],
  });
  const resetFilters = () => {
    setStartDate(null); 
    setEndDate(null);  
    fetchStoresStatus();
    setStore({label: "País", value: "54"})
  };

  useEffect(() => {
    fetchStoresStatus();
  }, [startDate, endDate, store]);  

const fetchStoresStatus = async () => {
  setLoad(true);

  setStoresStatus([]);
  setStoresSeguimiento([]);

  const fecha = new Date('2024-10-01T00:00:00-06:00');
  const primerDiaAnio = new Date(fecha.getFullYear(), 0, 1);
  const fechaActual = new Date();
  fechaActual.setHours(fechaActual.getHours() - fechaActual.getTimezoneOffset() / 60 - 6);

  let start = startDate || primerDiaAnio;
  let end = endDate || fechaActual;

  try {
    const response = await axios({
      url: `${BACKEND}api/cms/stores-status/` + start.toISOString() + "_" + end.toISOString(),
      method: "GET",
      headers: { Authorization: "Bearer " + token },
    });

    setStoresStatus(response.data.data);

    const updatedStoresSeguimiento = response.data.data.reduce((acc, element) => {
      if (element.store === store.label) {
        const seguimiento = (((element.job_finished + element.job_hired) /
          (element.job_finished + element.job_hired + element.job_inprocess + element.job_pending)) * 100).toFixed(2);

        acc.push({
          seguimiento,
          job_pending: element.job_pending,
          job_inprocess: element.job_inprocess,
          job_finished: element.job_finished,
          job_hired: element.job_hired
        });
      }
      return acc;
    }, []);

    setStoresSeguimiento(updatedStoresSeguimiento);

    fetchStoresStatusInterior();
    fetchStoresStatusValle();
  } catch (error) {
    console.log(error);
  } finally {
    setLoad(false);
  }
};

  return (
    <>
      <SubNavBack />
      <ToastContainer />

      <div className="container-fluid  bg-back">
        <div className="row justify-content-center">
          <div className="col-10  bg-white bgShadow mb-3 animate__animated animate__fadeIn">
            <div className="row  justify-content-center">

                <div className="row pl-2 col-12 pt-1">
                <Col xs={12}  md={6}>
                  <p className="title-selection">Selecciona un rango de fechas</p>
                  <form onSubmit={fetchStoresStatus} className="form-date">
                  
                    <div>
                      
                      <label>Fecha de inicio:</label> <br />
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        dateFormat="yyyy/MM/dd"
                        placeholderText="Inicio"
                        showYearDropdown
                        yearDropdownItemNumber={5} 
                        scrollableYearDropdown
                      />
                    </div>
                    <div>
                      <label>Fecha de fin:</label><br />
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        dateFormat="yyyy/MM/dd"
                        placeholderText="Fin"
                        showYearDropdown
                        yearDropdownItemNumber={5}
                        scrollableYearDropdown
                      />
                    </div>
                    <button type="submit" className="filtro-btn">Filtrar</button>
                    <button type="button" className="reset-btn filtro-btn" onClick={resetFilters}>Resetear</button>
                  </form>
                  </Col>
                  <Col xs={12}  md={5}>
                    <p className="title-selection">Seleccione una opción para ver los detalles.</p>
                      <CustomSelect
                          titleColor="#3643ba"
                          options={stores}
                          value={store}
                          setValue={setStore}
                      />
                  </Col>
                </div>

            

              <div className="mb-3 col-5 pt-5 ">
                  <Table responsive striped bordered hover className="center">
                    <thead>
                      <tr>
                        <th>Ofertas activas</th>
                        <th>Total de postulantes</th>
                        <th>Perfiles en Base de Datos</th>
                        <th>Porcentaje de Seguimiento</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>                
                          {storesDash.map((dash, i) => (
                              <React.Fragment key={i}>
                                  {dash.vacantes}
                              </React.Fragment>
                            ))
                          }
                        </td>
                        <td>                
                          {storesDash.map((dash, i) => (
                              <React.Fragment key={i}>
                                  {dash.offers}
                              </React.Fragment>
                            ))
                          }
                        </td>
                        <td>                
                          {storesDash.map((dash, i) => (
                              <React.Fragment key={i}>
                                  {dash.dataB}
                              </React.Fragment>
                            ))
                          }
                        </td>
                        <td>                
                          {storesSeguimiento.map((dash, i) => (
                              <React.Fragment key={i}>
                                  {dash.seguimiento}%
                              </React.Fragment>
                            ))
                          }
                        </td>
                      </tr>

                    </tbody>
                  </Table>

                  <Table responsive striped bordered hover className="center">
                    <thead>
                      <tr>
                        <th>Perfiles pendientes</th>
                        <th>Perfiles en proceso</th>
                        <th>Procesos Finalizados</th>
                        <th>Contratados</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>                
                          {storesSeguimiento.map((dash, i) => (
                              <React.Fragment key={i}>
                                  {dash.job_pending}
                              </React.Fragment>
                            ))
                          }
                        </td>
                        <td>                
                          {storesSeguimiento.map((dash, i) => (
                              <React.Fragment key={i}>
                                  {dash.job_inprocess}
                              </React.Fragment>
                            ))
                          }
                        </td>
                        <td>                
                          {storesSeguimiento.map((dash, i) => (
                              <React.Fragment key={i}>
                                  {dash.job_finished}
                              </React.Fragment>
                            ))
                          }
                        </td>
                        <td>                
                          {storesSeguimiento.map((dash, i) => (
                              <React.Fragment key={i}>
                                  {dash.job_hired}
                              </React.Fragment>
                            ))
                          }
                        </td>
                      </tr>

                    </tbody>
                  </Table>

              </div>

              <div className="mb-3 col-7 "> 
                  <div style={{ width: '680px', height: '360px' }}>
                    <AgChartsReact options={optionsSegPais} />
                  </div>
              </div>

              <div className="titBack text-left col-6 my-md-5 pt-md-5" style={{ width: '1200px'}}>
                <Card className="text-center">
                  <Card.Header>TIEMPO PROMEDIO PARA TRATAR MIS CANDIDATOS</Card.Header>
                  <Card.Body>
                    <Card.Title>
                      
                          {storesPromContratarTratar.map((dash, i) => (
                            

                              <React.Fragment key={i}>
                                  { dash.diasPromedioTratar }
                              </React.Fragment>
                            ))
                          }
                            días
                    </Card.Title>
                
                  </Card.Body>
                </Card>
              </div>
              
              <div className="titBack text-left col-6 my-md-5 pt-md-5" style={{ width: '1200px'}}>
                <Card className="text-center">
                  <Card.Header>TIEMPO PROMEDIO PARA CONTRATAR MIS PERFILES</Card.Header>
                  <Card.Body>
                    <Card.Title>
                      
                        {storesPromContratarTratar.map((dash, i) => (
                            <React.Fragment key={i}>
                                { dash.diasPromedioContratar }
                            </React.Fragment>
                          ))
                        }
                          días
                    </Card.Title>
             
                  </Card.Body>
                </Card>
              </div>
              <div className="mb-3 col-6 "> 
              <div  style={{ width: '100%', height: 'auto' }}>
                <AgChartsReact options={optionsSeg} />
              </div>
              </div>
              <div className="mb-3 col-6 "> 
              <div  style={{ width: '100%', height: 'auto' }}>
                <AgChartsReact options={optionsSegContratar} />
              </div>
              </div>

             
     
              <div className="row  mb-3 col-10 ">
                <Table responsive striped bordered hover className="center">
                  <thead>
                    <tr>
                      {/* <th>Top</th> */}
                      <th>Tienda</th>
                      <th>% Seguimiento</th>
                    </tr>
                  </thead>
                  <tbody>
                    
                  {uniqueStoresStatus.map((store, i) => (
                    <tr key={i}>
                      <td>
                        {store.store}
                      </td>
                      <td>
                        {(((store.job_finished + store.job_hired) / (store.job_finished + store.job_hired + store.job_inprocess + store.job_pending)) * 100).toFixed(2)} %
                      </td>
                    </tr>
                  ))}

                  </tbody>
                </Table>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboardh;