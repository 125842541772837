import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from "styled-components";
import header1 from "../styles/header-foto-1.jpg";
import header2 from "../styles/header-foto-2.jpg";
import move from "../styles/sentido-foto-1.jpg";
import quieneSomos from "../styles/textura.jpg";
import accesible from "../styles/galeria-decathlon-accesible.jpg";
import diverso from "../styles/galeria-decathlon-diverso.jpg";
import innovador from "../styles/galeria-decathlon-innovador.jpg";
import tips from "../styles/tips-foto-mobile.jpg";
import sustentable from "../styles/galeria-decathlon-sustentable.jpg";
import majo from "../styles/img-carrera-1.png";
import flecha from "../styles/boton-carrera.svg";
import flech from "../styles/flecha.png";
import Team from "./Team";
import Carousel from './Carousel';

import { Link } from "react-router-dom";
const images = [
  { id: 1, src: require('../styles/carrusel-por-que-tu-autenticidad.jpg'), alt: 'Autenticidad' },
  { id: 2, src: require('../styles/carrusel-por-que-tu-generosidad.jpg'), alt: 'Generosidad' },
  { id: 3, src: require('../styles/carrusel-por-que-tu-responsabilidad.jpg'), alt: 'Responsabilidad' },
  { id: 4, src: require('../styles/carrusel-por-que-tu-vitalidad.jpg'), alt: 'Vitalidad' },
];

const carouselImg = [
  <img src={accesible} alt="Slide 1" />,
  <img src={diverso} alt="Slide 2" />,
  <img src={sustentable} alt="Slide 3" />,
  <img src={innovador} alt="Slide 4" />
];

const CarruselContainer = styled.div`
  position: relative;
  width: 500px; /* Ajusta el ancho según tus necesidades */
  height: 500px; /* Ajusta la altura según tus necesidades */
  overflow: hidden;
  @media (max-width: 767px) {
  height: 400px;
  }
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  transition: opacity 0.3s;
  opacity: ${(props) => (props.visible ? 1 : 0)};
`;

const Option = styled.li`
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  padding: 5px 10px;
  
  color: ${(props) => (props.selected ? '#3643BA' : 'black')}; /* Cambia el color del texto */

  &:hover {
    background-color: #f0f0f0; /* Color de fondo al pasar el mouse */
    text-decoration-line:none;
  }

  &::after {
    content: '→'; /* Carácter de flecha */
    margin-left: 5px;
    visibility: ${(props) => (props.selected ? 'visible' : 'hidden')};
    color: #3643BA; /* Color de la flecha */
  }
`;

const Carrusel = () => {
  const [selectedImage, setSelectedImage] = useState(images[0]);

  const handleClick = (image) => {
    setSelectedImage(image);
  };

  return (
    <>
      <div className="porque_tu_div">
        <p className="porque_tu">¿QUIÉNES SOMOS?</p>
        <ul>
          {images.map((image) => (
            <Option key={image.id} onClick={() => handleClick(image)} selected={selectedImage.id === image.id}>
              {image.alt}
            </Option>
          ))}
        </ul>
      </div>
      <CarruselContainer>
        {images.map((image) => (
          <Image
            key={image.id}
            src={image.src.default}
            alt={image.alt}
            visible={selectedImage.id === image.id}
          />
        ))}
      </CarruselContainer>
    </>
  );
};


export const Home = () => {

  const scroll = (event) => {
    event.preventDefault(); // Prevenir recarga
    window.scroll({
        top: 5360,
        left: 100,
        behavior: "smooth",
    });
  };

  return (
    <>
    <section>
      <Row>
        <Col xs={12} md={4} style={{padding:'0'}}>
          <div className='img-1'>
            <img src={header1} alt="Imagen 1" style={{width:'100%'}}/>
          </div>
        </Col>
        <Col xs={12} md={4} style={{padding:'0'}}>
          <div className="text-middle">
            <p>Únete a la <span className="green">aventura</span></p>
            <p>Atrévete a mover a la gente a través de las maravillas del deporte</p>
            <a href="" onClick={scroll} className='btn-conoce'><span className="btn-offer text-hide-mobile" ><b>CONOCE NUESTRAS VACANTES</b></span></a>
            
            {/* <p className="descubre text-hide-mobile">DESCUBRE DECATHLON</p> */}
            <img src={flech} className='imagen-animada'/>
          </div>
        </Col>
        <Col xs={12} md={4} style={{padding:'0'}} className='img-2'>
          <img src={header2} alt="Imagen 1" className="" style={{width:'100%'}}/>
        </Col>
      </Row>
    </section>
    <section className="video-image-container">
      <div className="move-people-div-mobile">
        <p className="title">&nbsp; NUESTRO SENTIDO</p>
        <p className="blue">Move people through <br />&nbsp;&nbsp;the wonders of sport</p>
      </div>
      <div className="move-people">
        <div className="move-people-div">
          <p className="title">&nbsp; NUESTRO SENTIDO</p>
          <p className="blue">Move people through <br />&nbsp;&nbsp;&nbsp;&nbsp;the wonders of sport</p>
        </div>
        <iframe src="https://www.youtube.com/embed/PPsfI4w5bmk?si=gOVAUcJ5kTdNFMWt"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                className="video-overlay">
        </iframe>
        <img src={move} alt="Imagen de fondo" className="" />
      </div>
    </section>
    <section className="por_que_tu">
      <Carrusel />
    </section>
    <section className="cultura_empresa">
      <div className="title">
        <p className="p">CULTURA <br /> <span className="span"> DE EMPRESA </span> </p> 
      </div>
      <p>En Decathlon amamos el deporte y lo celebramos como un estilo de vida innovador.</p>
      <p>Valoramos la diversidad y nos esforzamos al máximo para que cada miembro de nuestro equipo </p>
      <p>pueda desarrollar su carrera profesional haciendo lo que más le apasiona.</p>
    </section>
    <section className="circle_cultura">
      <Container>
        <Row>
          <Col  xs={6} md={3} className="col-circle"><div className="circle"><p>+700</p></div><p>Colaboradores en México</p></Col>
          <Col  xs={6} md={3} className="col-circle"><div className="circle"><p className="text">Más de <br /><p className="number">105,000</p></p></div><p>Colaboradores <br />alrededor del mundo</p></Col>
          <Col  xs={6} md={3} className="col-circle"><div className="circle"><p>15</p></div><p>Tiendas +1 centro <br /> logístico en México</p></Col>
          <Col  xs={6} md={3} className="col-circle"><div className="circle"><p>+40</p></div><p>Productos hechos en México</p></Col>
        </Row>
      </Container>
    </section>
    <section className="quienes-somos">
    <p className="title_mobile">¿QUIÉNES <span style={{color:'#3643BA'}}>SOMOS?</span></p>
      <Row>
        <Col xs={5} md={6} ><img className="mob-img" src={quieneSomos} />  </Col>
        <Col xs={7} md={6}>  
          <p className="title">¿QUIÉNES <span style={{color:'#3643BA'}}>SOMOS?</span></p>
          <p> En Decathlon, vivirás una experiencia divertida y desafiante, potenciando tus habilidades y desarrollándote con autenticidad.</p>
          <p> Valoramos tu experiencia, destreza y perspectivas innovadoras para enfrentar grandes retos</p>
          <Col md={{ span: 7, offset: 7 }} className="link_quienes"><Link to="/quienes-somos">Conoce más sobre Decathlon <p className='icon'>&gt;</p></Link> </Col>
        </Col>
        
      </Row>
      <Row className="images-top">
        <Col md={{ span: 9, offset: 3 }}>
          <div className="images-quienes">
            <img src={accesible} />
            <img src={diverso} />
            <img src={sustentable} />
            <img src={innovador} />
          </div>
          
        </Col>
       
      </Row>
      <Row>
        <Col xs={7}>
          <Carousel items={carouselImg} interval={2000} className="carouselimg" />
        </Col>
      </Row>
    </section>
    <section className="carrera">
      <Row>
        <Col xs={12} md={5} style={{padding:'0'}}>
          <div className="carrera-img">
            <img className="mob-img" src={majo} />  
          </div>
        </Col>
        <Col xs={12} md={7} style={{padding:'0'}}>
          <div className="carrera-text">
            <p className="p">TU CARRERA <br /> <span className="span"> EN DECATHLON </span> </p> 
            <p> En Decathlon tú escribes el recorrido profesional que quieres vivir. El colaborador es el principal motor de su propio desarrollo, y ponemos todo de nuestra parte para acompañarte en tus objetivos.</p>
            <b>La historia de Majo:</b>
            <p>Empecé como líder de deportes acuáticos en Decathlon Querétaro, mi deporte pasión es la natación y amo compartir mi experiencia con otros apasionados del deporte...</p>
            <span className="span link" style={{float:'right'}}><b><Link to="/carrera" style={{color:'#7AFFA6'}}>Descubre algunos recorridos&nbsp;<img src={flecha} className="flecha"/></Link></b></span> 
          </div>
        </Col>
      </Row>
    </section>
    <section className='tips_sec'>
      <Row>
        <Col xs={12} md={8}>
          <div className='tips'>
            <div className="title"><p className="p">TIPS PARA PREPARAR  <br/> <span className="span"> TU PROCESO</span> </p></div>
            <img className='mobile' src={tips} />
            <p>En Decathlon nos divertimos, nos retamos y somos apasionados del deporte.</p>
            <p>Valoramos la autenticidad y jugamos en equipo buscando ser diferentes.</p>
          </div>
        </Col>
        <Col xs={12} md={7}>
          <div className='tip_desc'>
            <p className='circle_num num-mob' >1</p><span style={{color: '#7AFFA6'}}> &nbsp; <b>En Decathlon, somos apasionadas y apasionados del deporte.</b> </span>
            <p style={{lineHeight: '16px'}}> Así que no dudes en compartirnos una foto al momento de postular, haciendo lo que te gusta practicar y en tu CV, tu experiencia deportiva.</p>
          </div>
        </Col>
        <Col xs={12} md={7}>
          <div className='tip_desc'>
            <p className='circle_num' >2</p><span style={{color: '#7AFFA6'}}> &nbsp; <b>Al momento de la entrevista, sin estrés.</b> </span><br/>
            <p style={{lineHeight: '16px'}}> Queremos conocerte tal y como eres. ¿Qué te hace únic@?</p>
          </div>
        </Col>
        <Col xs={12} md={7}>
          <div className='tip_desc'>
            <p className='circle_num' >3</p><span style={{color: '#7AFFA6'}}> &nbsp; <b>La vitalidad y la autenticidad.</b> </span><br/>
            <p style={{lineHeight: '16px'}}> Son valores que buscamos y vivimos en Decathlon.</p>
          </div>
        </Col>
        <Col xs={12} md={7}>
          <div className='tip_desc'>
            <p className='circle_num' >4</p><span style={{color: '#7AFFA6'}}> &nbsp; <b>Nadie es perfecto.</b> </span>
            <p style={{lineHeight: '16px'}}> Así que sé sincero con tus experiencias y ten curiosidad por lo que puedes aprender y desarrollar en Decathlon.</p>
          </div>
        </Col>
      </Row>
    </section>
    <section>
      <Team />
    </section>
  </>
  
  );
};

export default Home;
