import { useReducer, useEffect } from "react";
import "./styles/App.css";
import Sidebar from "./components/Sidebar";
import { HashRouter as Router, Switch, Route, useHistory, Redirect } from "react-router-dom"; // useHistory es el hook para manejar la historia
import Home from "./pages/Home";
import Team from "./pages/Team";
import Perfil from "./pages/Perfil";
import Accesible from "./pages/Accesible";
import Sustentable from "./pages/Sustentable";
import Digital from "./pages/Digital";
import Diverso from "./pages/Diverso";
import Innovacion from "./pages/Innovacion";
import Cultura from "./pages/Cultura";
import Footer from "./components/footer/Footer";
import Carrera from "./pages/Carrera";
import QuienesSomos from "./pages/QuienesSomos";
import DescOfert from "./pages/DescOferta";
import { AuthContext } from "./auth/AuthContext";
import { authReducer } from "./auth/authReducer";
import Recuperar from "./pages/Recuperar";
import Restablecer from "./pages/Restablecer";

import AdminVacantes from './components/cms/auth/admin-vacantes'
import CrearVacante from "./components/cms/auth/crear-vacante";
import BaseDatosCv from "./components/cms/baseDatosCv";
import Soporte from "./components/cms/auth/soporte";
import Dashboardh from "./components/cms/auth/dashboardh";
import Logger from './components/cms/auth/logger';
import AdminStatus from './components/cms/auth/admin-status';

import "animate.css";

const init = () => {
  return JSON.parse(localStorage.getItem("user")) || { logged: false };
};

function App() {
  const [user, dispatch] = useReducer(authReducer, {}, init);

  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(user));
  }, [user])
  //const user = useContext(AuthContext)
  return (
    <div className="content">
      <Router>
        <AuthContext.Provider value={{ user, dispatch }}>
          <Sidebar />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/team/:store_id?" exact component={Team} />
            <Route path="/perfil/:job_id?" exact component={Perfil} />
            <Route path="/Accesible" exact component={Accesible} />
            <Route path="/sustentable" exact component={Sustentable} />
            <Route path="/digital" exact component={Digital} />
            <Route path="/diverso" exact component={Diverso} />
            <Route path="/innovacion" exact component={Innovacion} />
            <Route path="/cultura" exact component={Cultura} />
            <Route path="/carrera" exact component={Carrera} />
            <Route path="/quienes-somos" exact component={QuienesSomos} />
            <Route path="/recuperar/:tkn_restore?" exact component={Recuperar} />
            <Route path="/restablecer" exact component={Restablecer} />
            <Route path="/desc_ofert/:job_id?" exact component={DescOfert} />
            <Route path="/cms/auth/admin-vacantes"  component={AdminVacantes} isAutenticated={ user.isAdmin}  />
            <Route path="/cms/auth/crear-vacante"  component={CrearVacante} isAutenticated={ user.isAdmin}  />
            <Route path="/cms/auth/base-datos-cv"  component={BaseDatosCv} isAutenticated={ user.isAdmin}  />
            <Route path="/cms/auth/soporte"  component={Soporte} isAutenticated={ user.isAdmin}  />
            <Route path="/cms/auth/dashboardh"  component={Dashboardh} isAutenticated={ user.isAdmin}  />
            <Route path="/cms/auth/admin-status/:id?"  component={AdminStatus} isAutenticated={ user.isAdmin}  />
            <Route path="/cms/auth:authization?:refresh?"  component={Logger} isAutenticated={ user.isAdmin}  />
            
            <Route component={NotFoundRedirect} />
          </Switch>
          <Footer />
        </AuthContext.Provider>
      </Router>
    </div>
  );
}

export default App;

const NotFoundRedirect = () => {
  const history = useHistory();

  useEffect(() => {
    history.replace("/"); 
  }, [history]);

  return (
    <div>
      <h1>404 - Página no encontrada</h1>
      <p>Redirigiendo...</p>
    </div>
  );
};
