import React, { useContext, useEffect, useState } from "react";
import { Menu } from "grommet-icons";
import exampleBack from "../../../components/images/exampleImgBack.png";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { types } from "../../../types/types";
import { AuthContext } from "../../../auth/AuthContext";
import { BACKEND } from "../../backend";

const SubNavBack = () => {
  const { dispatch } = useContext(AuthContext);

  let history = useHistory();
  const [saludo, setSaludo] = useState("");

  const [res, setRes] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem("token");

  const baseUrlSubNav = BACKEND + "api/common/profile";
  const fetchSaludo = async () => {
    setLoading(true);
    try {
      const response = await axios({
        url: baseUrlSubNav,
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      });
      setRes(response);

      setLoading(false);
      setError(null);
    } catch (error) {
      dispatch({
        type: types.adminlogout,
      });
      setLoading(false);
      setError(error);
      localStorage.clear();
      history.replace("/");
      window.location.reload();
    } finally {
    }
  };
  useEffect(() => {
    fetchSaludo();
  }, []);

  const logout = e => {
      // e.preventDefault();
      localStorage.removeItem('refresh')
      localStorage.removeItem('user')
      localStorage.removeItem('token')
      window.location.reload();
  }

  return (
    <>
      <div className="container-fluid  bg-back">
        <div className="row justify-content-end">
          <div className=" row subNavBack justify-content-center text-left   ">
            <div className=" container-fluid  ">
              <div className="row justify-content-center align-content-center align-items-center">
                <div className="col-8 col-md-10 pl-1">
                  <div className="col-6  col-md-9 offset-md-3 align-middle pl-0 ">
                    {JSON.parse(localStorage.getItem("user")) &&
                      JSON.parse(localStorage.getItem("user")).isAdmin ===
                        true && (
                        <span className="subMenuTxtHola align-middle my-auto">
                          {!error && res !== null && res.data.email}
                          <span className="subMenuTxtUser"> {saludo}</span>
                        </span>
                      )}
                  </div>
                </div>
                <div
                  className="col-4  col-md-2 py-2 px-1 bhNaranja subMenuTxt"
                  role="button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {" "}
                  <Menu color="white" />{" "}
                  <span className=" align-middle">Mis acciones</span>{" "}
                </div>

                <div
                  className="dropdown-menu "
                  aria-labelledby="dropdownMenuLink"
                >
                  <Link
                    className="dropdown-item subMenuOpt p-2"
                    to="/cms/auth/crear-vacante"
                  >
                    Crear una vacante
                  </Link>
                  <Link
                    className="dropdown-item subMenuOpt p-2"
                    to="/cms/auth/admin-vacantes"
                  >
                    Administrar mis vacantes
                  </Link>
                  <Link
                    className="dropdown-item subMenuOpt p-2"
                    to="/cms/auth/base-datos-cv"
                  >
                    Base de datos CV
                  </Link>
                  <a
                      href="https://storage.googleapis.com/archivos-mx/trabaja-con-nosotros/manual.pdf"
                      target="_blank"
                      className="dropdown-item subMenuOpt p-2"
                  >
                    Manual / Contacto
                  </a>
                  {/* <Link
                    className="dropdown-item subMenuOpt p-2"
                    to="/cms/auth/soporte"
                  >
                    Soporte / Contacto
                  </Link> */}
                  <Link
                    className="dropdown-item subMenuOpt p-2"
                    to="/cms/auth/dashboardh"
                  >
                    Dashboard Humano
                  </Link>
                  <Link
                    className="dropdown-item subMenuOpt p-2"
                    to="/cms/auth/soporte"
                  >
                    Soporte / Contacto
                  </Link>
                  <Link
                    className="dropdown-item subMenuOpt p-2"
                    to=""
                    onClick={logout}
                  >
                    Salir
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-11 pl-5 ">
            <img
              src={exampleBack}
              className="rounded-circle imgBackUser"
              width="140px"
              alt=""
            />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default SubNavBack;
