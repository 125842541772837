import React, { useRef, useState, useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import logo from "./images/main-logo.png";
import User from "./images/user.png";
import { DivisorLogin } from "./DivisorLogin";
import useOnClickOutside from "../hooks/useSidebar";
import { AuthContext } from "../auth/AuthContext";

const Nav = styled.div`
  background: #F5F4F5 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  position: relative;

  @media screen and (max-width: 768px) {
    padding: 0 10px;
  }
`;

const NavIcon = styled(Link)`
  font-size: 2rem;
  display: flex;
  align-items: center;
`;

const DesktopMenu = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const MobileMenuIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    font-size: 2rem;
    cursor: pointer;
  }
`;

const MobileNavMenu = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 999;
    padding: 20px;
    transform: ${({ isOpen }) => (isOpen ? "translateX(0)" : "translateX(-100%)")};
    transition: transform 0.3s ease-in-out;
  }
`;

const MobileNavLink = styled(Link)`
  display: block;
  padding: 10px 0;
  font-size: 1.2rem;
  text-decoration: none;
  color: #000;

  &:hover {
    color: #007bff;
  }
`;

const CloseButton = styled(FaTimes)`
  font-size: 2rem;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  color: #000;
`;

const ModalOverlay = styled.div`
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
position: fixed; 
  bottom: 100%; 
  left: 50%; 
  transform: translateX(-50%); 
  width: 90%; 
  max-width: 500px; 
  z-index: 1000; /
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const Sidebar = () => {
  const ref = useRef();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isDrop, setIsDrop] = useState(false);
  const [user, setUser] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useOnClickOutside(ref, () => setMobileMenuOpen(false));

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLoginClick = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const Logout = () => {
    document.body.click();
    localStorage.clear();
    window.location.reload();
  };

  return (
    <div style={{ backgroundColor: "#F5F4F5" }}>
      <Nav className="nav">
        <NavIcon to="/">
          <img src={logo} alt="logo" className="logo-decathlon" />
        </NavIcon>

        <DesktopMenu>
          <NavIcon to="/quienes-somos">
            <span className="span pl-04 va-b">¿QUIÉNES SOMOS?</span>
          </NavIcon>
          <NavIcon to="/carrera">
            <span className="span pl-04 va-b">TU CARRERA EN DECATHLON</span>
          </NavIcon>
          <NavIcon to="/team">
            <span className="span pl-04 va-b offer">OFERTAS DE TRABAJO</span>
          </NavIcon>
        </DesktopMenu>

        <MobileMenuIcon onClick={handleMobileMenuToggle}>
          {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
        </MobileMenuIcon>

        {/* Mobile Menu */}
        <MobileNavMenu isOpen={isMobileMenuOpen} ref={ref}>
          <CloseButton onClick={handleMobileMenuToggle} />
          <MobileNavLink to="/quienes-somos" onClick={handleMobileMenuToggle}>
            ¿QUIÉNES SOMOS?
          </MobileNavLink>
          <MobileNavLink to="/carrera" onClick={handleMobileMenuToggle}>
            TU CARRERA EN DECATHLON
          </MobileNavLink>
          <MobileNavLink to="/team" onClick={handleMobileMenuToggle} className="offer-mb">
            OFERTAS DE TRABAJO
          </MobileNavLink>
        </MobileNavMenu>
      </Nav>

      {/* Modal */}
      {showModal && (
          <ModalOverlay isOpen={showModal}>
            <ModalContent className="content-modal">
              <CloseButton onClick={handleModalClose} style={{ position: 'absolute', top: '10px', right: '10px' }} />
              <DivisorLogin setIsDrop={setIsDrop} setUser={setUser} />
            </ModalContent>
          </ModalOverlay>
        )}
    </div>
  );
};

export default Sidebar;
