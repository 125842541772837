import React, { useRef, useState, useEffect } from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import User from "../images/icon-user.svg";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { DivisorLogin } from "../DivisorLogin";
import useOnClickOutside from "../../hooks/useSidebar";
import Tiktok from "../images/icons-rrss-tiktok.svg";
import Instagram from "../images/icons-rrss-instagram.svg";
import Facebook from "../images/icons-rrss-facebook.svg";
import Youtube from "../images/icons-rrss-youtube.svg";
import Linkedin from "../images/icons-rrss-linkedin.svg";

const NavIcon = styled(Link)`
  font-size: 2rem;
  display: flex;
  align-items: center;
`;

const DesktopMenu = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
  }
`;

const Footer = () => {
  const ref = useRef();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isDrop, setIsDrop] = useState(false);
  const [user, setUser] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useOnClickOutside(ref, () => setMobileMenuOpen(false));
  const Logout = () => {
    document.body.click();
    localStorage.clear();
    window.location.reload();
  };

  useEffect(() => {
    setShowModal(false);
    setIsDrop(false);
}, []);

  return (
    <>
      <footer className="footer">
        <Col xs={12} md={8} className="foo">
          <Row className="row-foo">
          <DesktopMenu>
            <NavIcon as={"div"}>
              {JSON.parse(localStorage.getItem("user")) &&
              JSON.parse(localStorage.getItem("user")).isAdmin === true ? (
                <div className={`dropdown`}>
                  <Link
                    to="/cms/auth/admin-vacantes"
                    className="btn btn-admin ml-4"
                  >
                    ADMINISTRACIÓN
                  </Link>
                </div>
              ) : (
                <>
                  {user ? (
                    <div className={`dropdown ${isDrop && "shows"}`}>
                      <span
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img className="pl-4 pr-3 uno" src={User} alt="user" />
                      </span>
                      <div
                        className="dropdown-menu dropdown-menu-right drpdw"
                        style={{ marginTop: "1em", width: "190px" }}
                        aria-labelledby="dropdownMenuButton"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <div className="col-lg-12">
                          <form autoComplete="off">
                            <div className="container-fluid text-center pt-2 mb-2">
                              <Link
                                className="btn-perfil mb-5"
                                to="/status"
                                onClick={() => document.body.click()}
                              >
                                <img className="pl-4 pr-3 dos" src={User} alt="user" />
                              </Link>
                            </div>
                            <div className="container-fluid text-center mb-2">
                              <Link
                                className="btn-perfil"
                                to="/"
                                onClick={Logout}
                              >
                                Cerrar sesión
                              </Link>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="dropdown">
                      
                      <span
                        id="dropupMenuButton"
                        aria-haspopup="true"
                        aria-expanded="false"
                        onClick={() => setIsDrop(!isDrop)}
                      >
                        <img className="pl-4 pr-3 drop-img" src={User} alt="user"/>
                      </span>
                      
                      <div
                        className={
                          isDrop
                            ? "dropdown-menu dropdown-menu-left drpdw animate__animated animate__fadeIn animate__faster show modal_login"
                            : "dropdown-menu dropdown-menu-left drpdw animate__animated animate__fadeIn animate__faster"
                        }
                        style={{
                          marginTop: "-23em",
                        }}
                        aria-labelledby="dropdownMenuButton"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <DivisorLogin
                          setIsDrop={setIsDrop}
                          setUser={setUser}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </NavIcon>
          </DesktopMenu>
              &nbsp; | &nbsp; © 2024 Reclutamiento Decathlon &nbsp; | &nbsp; Todos los derechos reservados
            </Row>
          </Col>
        <Col xs={12} md={3}>
          <Row className="icon-soc">
            <a href="https://www.facebook.com/DecathlonMexico/">
              <img src={Facebook} alt="Facebook" className="icon_social" />
            </a>
            <a href="https://www.instagram.com/decathlon_mx/?hl=en">
              <img src={Instagram} alt="Instagram" className="icon_social" />
            </a>
            <a href="https://www.youtube.com/channel/UCLBtITHHNjaevRunUk3sT0g">
              <img src={Youtube} alt="Youtube" className="icon_social" />
            </a>
            <a href="https://www.tiktok.com/@decathlonmexico">
              <img src={Tiktok} alt="Instagram" className="icon_social" />
            </a>
            <a href="https://www.linkedin.com/company/decathlon-mexico/mycompany/">
              <img src={Linkedin} alt="Linkedin" className="icon_social" />
            </a></Row>
            </Col>

      </footer>
    </>
  );
};



export default Footer;
