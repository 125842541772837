// import React from 'react';
// import Word from "../components/images/worldwide.png";
// import Vector from "../components/images/Vector.png";
// import { Container, Col, Row, Carousel} from "react-bootstrap";



// const Digital = () => {
//     return (
//       <Container style={{maxWidth:"100%", padding:"0"}} className="digital">
//         <div className="fondoimagen1">
//           <Row>
//             <Col>
//               <p>¿QUÉ HACEMOS PARA HACER EL DEPORTE ACCESIBLE?</p>
//             </Col>
//           </Row>
//         </div>
        
//         <Row className="row-carr">
//           <Col style={{display: "flex",justifyContent: "center",alignItems: "center"}}>
//             <div className="acc-1">
//               <img src={Word} />
//               <p>Nuestra misión como empresa es:  "Ser útiles a las personas y el planeta" y para ellos, uno de los caminos que hemos elegido es hacer el placer y los beneficios del deporte accesible al mayor número de personas. </p>
//             </div>
//           </Col>
//           <Col style={{display: "flex",justifyContent: "center",alignItems: "center"}}>
//             <div className="acc-2">
//               <img src={Vector} />
//               <p>Cuando hablamos de accesibilidad, nos referimos a acercar nuestra tiendas físicas, digitales y diferentes canales de venta a los usuarios y también hacerlo a un precio que permita a cualquier persona inciarse a un deporte. </p>
//             </div>
//           </Col>
//           <Col style={{display: "flex",justifyContent: "center",alignItems: "center"}}>
//             <div className="acc-1">
//               <img src={Word} />
//               <p>Además, creemos en el deporte como vector de integración, es por eso que trabajamos diferentes proyectos sociales con colectivos de vulnerabilidad  bajo nuestra "Fundación Decathlon". </p>
//             </div>
//           </Col>
//         </Row>
//         <Row className="carrusel">
//           <Carousel variant="dark">
//             <Carousel.Item>
//               <div className="acc-1">
//                 <img src={Word} />
//                 <p>Nuestra misión como empresa es:  "Ser útiles a las personas y el planeta" y para ellos, uno de los caminos que hemos elegido es hacer el placer y los beneficios del deporte accesible al mayor número de personas. </p>
//               </div>
//             </Carousel.Item>
//             <Carousel.Item>
//               <div className="acc-2">
//                 <img src={Vector} />
//                 <p>Cuando hablamos de accesibilidad, nos referimos a acercar nuestra tiendas físicas, digitales y diferentes canales de venta a los usuarios y también hacerlo a un precio que permita a cualquier persona inciarse a un deporte. </p>
//               </div>
//             </Carousel.Item>
//             <Carousel.Item>
//               <div className="acc-1">
//                 <img src={Word} />
//                 <p>Además, creemos en el deporte como vector de integración, es por eso que trabajamos diferentes proyectos sociales con colectivos de vulnerabilidad  bajo nuestra "Fundación Decathlon". </p>
//               </div>
//             </Carousel.Item>
//           </Carousel>
//         </Row>
//         <Row className="row-vid">
//           <p className="vid-p">En el siguiente vídeo, Alicia y Miriam te presentan el proyecto que llevaron a cabo.</p>
//         </Row>
//         <Row className="row-vid">
//           <iframe
//             height="315"
//             src="https://www.youtube.com/embed/If_4pkLxY6g"
//             title="YouTube video player"
//             frameBorder="0"
//             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//             allowFullScreen
//             className="vid-dig"
//           ></iframe>
//         </Row>
        
//       </Container>
//     );
//   };

// export default Digital;
                        

import React from 'react';
import { Container, Col, Row } from "react-bootstrap";
import video from "../components/images/rectangle.png";
import casa from "../components/images/casa.png";

function Digital() {
    return (
        <>
            <div className="innovacion ">
                <div className="bg-color-transparent flex-cntnt-cult">
                    <Row>
                        <p className="title-inn">LA INNOVACIÓN FORMA PARTE DE NUESTRO ADN</p> 
                        <br />
                        <p className="title-inn--l">¿Quieres saber más?  </p>
                        <p className="title-inn--m">¡Conoce nuestra visión para los dos próximos años!</p>
                    </Row>
                    <Row >
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/Zp8yR-zZcTY?si=z6f74qTfuariDOuu" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </Row>
                    <Row>
                        <Col style={{padding: "95px 0"}} md={6} xs={12}>
                        <p className="inn-p">Vivimos en un entorno en constante cambio y las necesidades de los deportistas no son una excepción.<br /><br />
                            En Decathlon innovamos para adaptarnos a las necesidades de nuestros usuarios.<br /><br />
                            La Innovación forma parte de nuestro ADN. Desde el diseño, hasta la venta en tienda pasando por la producción y la logística. </p>
                        </Col>
                        <Col xs={11} md={6}>
                            <img src={casa} alt="" className="inn-img" />
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default Digital;

