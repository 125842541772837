import React from 'react';
import Word from "../components/images/worldwide.png";
import Vector from "../components/images/Vector.png";
import { Container, Col, Row, Carousel} from "react-bootstrap";


const Accesible = () => {
    return (
      <Container style={{maxWidth:"100%", padding:"0"}} className="digital">
      <div className="fondoimagen1">
        <Row>
          <Col>
            <p>¿QUÉ HACEMOS PARA HACER EL DEPORTE ACCESIBLE?</p>
          </Col>
        </Row>
      </div>
      
      <Row className="row-carr" style={{marginRight: "0", marginLeft: "0"}}>
        <Col style={{display: "flex",justifyContent: "center",alignItems: "center"}}>
          <div className="acc-1">
            <img src={Word} />
            <p>Nuestra misión como empresa es:  "Move people through the wonders of sports" y para ello, uno de los caminos que hemos elegido es hacer el placer y los beneficios del deporte accesible al mayor número de personas. </p>
          </div>
        </Col>
        <Col style={{display: "flex",justifyContent: "center",alignItems: "center"}}>
          <div className="acc-2">
            <img src={Vector} />
            <p>Cuando hablamos de accesibilidad, nos referimos a acercar nuestra tiendas físicas, digitales y diferentes canales de venta a los usuarios y también hacerlo a un precio que permita a cualquier persona inciarse a un deporte. </p>
          </div>
        </Col>
        <Col style={{display: "flex",justifyContent: "center",alignItems: "center"}}>
          <div className="acc-1">
            <img src={Word} />
            <p>Además, creemos en el deporte como vector de integración, es por eso que trabajamos diferentes proyectos sociales con colectivos de vulnerabilidad  bajo nuestra "Fundación Decathlon". </p>
          </div>
        </Col>
      </Row>
      <Row className="carrusel">
        <Carousel variant="dark">
          <Carousel.Item>
            <div className="acc-1">
              <img src={Word} />
              <p>Nuestra misión como empresa es:  "Ser útiles a las personas y el planeta" y para ellos, uno de los caminos que hemos elegido es hacer el placer y los beneficios del deporte accesible al mayor número de personas. </p>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="acc-2">
              <img src={Vector} />
              <p>Cuando hablamos de accesibilidad, nos referimos a acercar nuestra tiendas físicas, digitales y diferentes canales de venta a los usuarios y también hacerlo a un precio que permita a cualquier persona inciarse a un deporte. </p>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="acc-1">
              <img src={Word} />
              <p>Además, creemos en el deporte como vector de integración, es por eso que trabajamos diferentes proyectos sociales con colectivos de vulnerabilidad  bajo nuestra "Fundación Decathlon". </p>
            </div>
          </Carousel.Item>
        </Carousel>
      </Row>
      <Row className="row-vid">
        <p className="vid-p">En el siguiente vídeo, Alicia y Miriam te presentan el proyecto que llevaron a cabo.</p>
      </Row>
      <Row className="row-vid">
        <iframe
          height="315"
          src="https://www.youtube.com/embed/tY63qaEY2SQ"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="vid-dig"
        ></iframe>
      </Row>
      
    </Container>
    );
  };

export default Accesible;
                        