import React, { useState, useEffect } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import YouTube from 'react-youtube';
import header from "../components/images/foto_header_desk.jpg";
import Andi from "../components/images/Andy.png";
import Majo from "../components/images/Majo.png";
import Benji from "../components/images/Benji.png";
import Entrena1 from "../components/images/entrena-y-crece-1.jpg";
import Entrena2 from "../components/images/entrena-y-crece-2.jpg";
import Entrena3 from "../components/images/entrena-y-crece-3.jpg";
import Team from "./Team";



function Carrera() {
  const [showModal, setShowModal] = useState(false);
  const [isDrop, setIsDrop] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setShowModal(false);
    setIsDrop(false);
  }, []);

  const videos = [
    // { id: 1, videoId: "U5W34MpMgMM", imgSrc: Andi, alt: "Andi", videoTitle: "▸ Conoce a Andy" },
    { id: 2, videoId: "gQwv1HPY2nU", imgSrc: Majo, alt: "Majo", videoTitle: "▸ Conoce a Majo" },
    { id: 3, videoId: "0WPKSe4h12Q", imgSrc: Benji, alt: "Benji", videoTitle: "▸ Conoce a Benji" }
  ];

  const [selectedVideo, setSelectedVideo] = useState(videos[0]);

  const handleVideoSelect = (video) => {
    setSelectedVideo(video);
  };

  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      autoplay: 0,
    },
  };

  return (
    <>
      <section className="carrera-header">
        <Row>
          <Col xs={12} md={7} className="back-carrera">
            <div className="title">
              <h3>TU CARRERA</h3>
              <h3 className="green">EN DECATHLON</h3>
            </div>
            <div className="carr-desc">
              <p>En Decathlon, el colaborador es el principal motor de su propio desarrollo, y ponemos todo de nuestra parte para acompañarte en tu objetivos.</p>
              <p><b>Tú escribes el recorrido profesional que quieres.</b></p>
            </div>
          </Col>
          <Col xs={12} md={5} className="back-img">
            <img src={header} alt="Carrera en Decathlon" />
          </Col>
        </Row>
      </section>

      <section className="historia">
        <Row>
          <Col xs={12} className="text-mobile"  style={{paddingLeft:'0'}}>
              <div className="video-selector-">
                <h2 className="">CONOCE LAS HISTORIAS DE ALGUNOS DE</h2>
                <h2 className="" style={{color:'#3643BA'}}>NUESTROS COLABORADORES</h2>
              </div>
            </Col>
          </Row>
      <Row className="align-items-center" >
        <Col xs={12} md={6} className="video-col" style={{paddingRight:'0'}}>
          <div className="video-container">
        
            <div className="youtube-video">
              <YouTube videoId={selectedVideo.videoId} opts={opts} />
            </div>
          </div>
        </Col>

        <Col xs={12} md={6} className="text-images-col img-click"  style={{paddingLeft:'0'}}>
          <div className="video-selector">
          <h2 className="">CONOCE LAS HISTORIAS DE ALGUNOS DE</h2>
          <h2 className="" style={{color:'#3643BA'}}>NUESTROS COLABORADORES</h2>
            <p className="p-desc">Conoce las historias de éxito de nuestros colaboradores y cómo se han desarrollado en sus respectivos oficios, descubre cómo vivimos el deporte de una forma diferente y atrévete a empezar a escribir tu historia con nosotros.</p>
            <ul>
              {videos.map((video) => (
                <li
                  key={video.id}
                  onClick={() => handleVideoSelect(video)}
                  style={{
                    cursor: 'pointer',
                    fontWeight: selectedVideo.id === video.id ? 'bold' : 'normal',
                  }}
                >
                  <img
                    src={video.imgSrc}
                    alt={video.alt}
                    className="img-click"
                    style={{ marginRight: '10px' }}
                  />
                  {video.videoTitle}
                </li>
              ))}
            </ul>
          </div>
        </Col>
      </Row>
    </section>
    <section className="entrena">
      <Row>
        <Col xs={12} md={12} className="entrena-title">
              <h2><b>ENTRENA Y CRECE</b></h2>
              <h2 className="green"><b>CON NOSOTROS</b></h2>
              <p>En Decathlon creemos en talentos como el tuyo desde el primer día.</p>
        </Col>
      </Row>
      <Row className="entrena-img">
        <Col  xs={12} md={4}>
          <img src={Entrena1} alt="Carrera en Decathlon" />
          <p>Un recorrido formativo que te ayudará a conseguir tus metas a lo largo de tu trayectoria en Decathlon.
          </p>
        </Col>
        <Col  xs={12} md={4}>
          <img src={Entrena2} alt="Carrera en Decathlon" />
          <p>Trabajamos como equipo y te acompañamos en tu progreso para que puedas avanzar de forma autónoma en tu oficio.</p>
        </Col>
        <Col  xs={12} md={4}>
          <img src={Entrena3} alt="Carrera en Decathlon" />
          <p id="nth">Un ecosistema de aprendizaje continuo para el desarrollo de habilidades y competencias donde tú serás el protagonista.
          </p>
        </Col>
      </Row>
    </section>
    <section>
          <Team />
        </section>
    </>
  );
}

export default Carrera;
