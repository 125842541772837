import React, { useState } from "react";
import { useFetch } from "../hooks/useFetch";

const SelectSports = ({ title, url, cat, onChange }) => {
  const [select, setSelect] = useState(title);

  const { data } = useFetch(url);

  if (!data) return null;

  let options = [];
  options = data.data[`${cat}`];

  const noCerrar = (e) => {
    if (cat === "cat_sports") {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const handleLvl = (id, e, lvl, descrip) => {
    e.preventDefault();
    onChange(id, lvl, descrip);
    setSelect(descrip);
  };

  return (
    <>
      <span
        id="dLabel"
        role="button"
        data-toggle="dropdown"
        className="cSelect nav-link dropdown-toggle"
        data-target="#"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {select} <span className="caret"></span>
      </span>
      <ul
        className="dropdown-menu multi-level w-100"
        role="menu"
        aria-labelledby="navbarDropdownMenuLink"
      >
        {options.map((category) => (
          <li key={category.sport_id}>
            <span className="dropdown-item d-inline-flex mt-auto">
              <div className="icon-link">
                <img src={category.sport_image} alt="" />
              </div>

              <ul key={category.sport_id} className="dropdown-submenu dropdown-item">
                {category.sport_name && category.sport_name !== null && category.sport_name === "Cualquiera" ? (
                  <>
                    {category.sub_sports.map((subCategory) => (
                      <span
                        className="dropdown-item item-text"
                        onClick={(e) => {
                          handleLvl(subCategory.id_sport_sub, e, 1, subCategory.description);
                        }}
                      >
                        {category.sport_name}
                      </span>
                    ))}
                  </>
                ) : (
                  <>
                    <span
                      className="dropdown-item item-text"
                      onClick={(e) => {
                        noCerrar(e);
                      }}
                    >
                      {category.sport_name}
                    </span>
                    <ul
                      className="dropdown-menu multi-level w-100"
                      role="menu"
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      {category.sub_sports.map((subCategory) => (
                        <li key={subCategory.id_sport_sub} className="dropdown-submenu mx-2">
                          <span
                            className="dropdown-item item-text pl-3"
                            onClick={(e) => {
                              noCerrar(e);
                            }}
                          >
                            {subCategory.description}
                          </span>
                          <ul className="dropdown-menu redWidth">
                            <li className="">
                              <span className="dropdown-item item-text pl-3 disabled text-dark">
                                Nivel de Práctica
                              </span>

                              <div className="rating justify-content-around">
                                <div
                                  data-rating="1"
                                  onClick={(e) =>
                                    handleLvl(subCategory.id_sport_sub, e, 3, subCategory.description)
                                  }
                                ></div>
                                <div
                                  data-rating="2"
                                  onClick={(e) =>
                                    handleLvl(subCategory.id_sport_sub, e, 2, subCategory.description)
                                  }
                                ></div>
                                <div
                                  data-rating="3"
                                  onClick={(e) =>
                                    handleLvl(subCategory.id_sport_sub, e, 1, subCategory.description)
                                  }
                                ></div>
                              </div>
                            </li>
                          </ul>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </ul>
            </span>
          </li>
        ))}
      </ul>
    </>
  );
};

export default SelectSports;
