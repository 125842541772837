import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

import axios from "axios";
import { Modal, Button, Spinner } from "react-bootstrap";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import SubNavBack from "./subNavBack";
import { Redirect } from "react-router-dom";
import defaul from "../../images/default.png";
import buso from "../../images/selectedBuso.png";
import acuaticos from "../../images/sports/acuaticos.png";
import carrera from "../../images/sports/carrera.png";
import caza from "../../images/sports/caza.png";
import ciclismo from "../../images/sports/ciclismo.png";
import colectivos from "../../images/sports/colectivos.png";
import combate from "../../images/sports/combate.png";
import gimnasia from "../../images/sports/gimnasia.png";
import montaña from "../../images/sports/montaña.png";
import olas from "../../images/sports/olas.png";
import presicion from "../../images/sports/presicion.png";
import raqueta from "../../images/sports/raqueta.png";
import urbanos from "../../images/sports/urbanos.png";

import lvl1 from "../../images/nivel1.png";
import lvl2 from "../../images/nivel2.png";
import lvl3 from "../../images/nivel3.png";
import ChatBack from "../../chatBack";
import { LoaderChico } from "../../LoaderChico";
import { BACKEND } from "../../backend";

import Comments from './Comments';
// import Iframe from 'react-iframe';

const AdminStatus = (userId) => {

  const user_Id = userId.match.params.id;

  const [res, setRes] = useState(null);
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState(null);
  const [cvLink, setCvLink] = useState(null);
  const [loading, setLoading] = useState(false);
  const [vacante, setVacante] = useState(0);
  const [applicant, setApplicant] = useState(null);
  const [resStatus, setResStatus] = useState(null);
  const [ok, setOk] = useState(false);
  const [user_id, setUser_id] = useState(null);
  const [showChat, setShowChat] = useState(false);
  const [show, setShow] = useState(false);
  const [showDel, setShowDel] = useState(false);
  const [showCv, setShowCv] = useState(false);
  const [candidato, setCandidato] = useState(null);
  const [oportunidades, setOportunidades] = useState({
    messages: `Hola,  Ante todo, gracias por tu interés en trabajar en Decathlon y por el tiempo que has dedicado a este proceso de selección. Este mail es para informarte de que tu perfil no se ajusta completamente a esta oferta pero… No te rindas! Hemos dejado tu candidatura en nuestra base de datos para que tengas nuevas oportunidades. Un saludo!`,
  });
  const [gracias, setGracias] = useState({
    messages: `Hola, Ante todo, gracias por tu interés en trabajar en Decathlon. Este mail es para informarte de que, después de evaluar tu candidatura, tu perfil no se ajusta completamente a nuestras necesidades. Muchas gracias por el tiempo invertido. Un saludo!`,
  });
  const [name, setName] = useState(null);
  const [resolve, setResolve] = useState(null);
  const [reason, setReason] = useState(null);
  const [eliminarCandidato, setEliminarCandidato] = useState(
    "Hola, Ante todo, gracias por tu interés en trabajar en Decathlon. Este mail es para informarte de que, después de evaluar tu candidatura, tu perfil no se ajusta completamente a nuestras necesidades. Muchas gracias por el tiempo invertido. Un saludo!"
  );
  const [eliminar, setEliminar] = useState(null);
  const [resEliminar, setResEliminar] = useState(null);
  const [resolveEliminar, setResolveEliminar] = useState(null);

  const [ showSpinnerGracias, setShowSpinnerGracias] = useState(false);
  const [ showSpinnerOportunidades, setShowSpinnerOportunidades] = useState(false);

  const [ applicantsStatusId, setApplicantsStatusId ] = useState(1);
  const [ jobStatusId, setJobStatusId ] = useState(0);


  const token = localStorage.getItem("token");

  const baseUrl = BACKEND + "api/cms/applicant/get-full-process";
  const baseUrlStatus = BACKEND + "api/cms/applicant/update-process";
  const baseUrlEmailProceso = BACKEND + "api/cms/send-email";
  const baseUrlEmailReason = BACKEND + "api/cms/send-email-disable";
  const baseUrlRemove = BACKEND + "api/applicant/remove-apply";
  const baseUrlDelete = BACKEND + "api/cms/delete-user";
  let history = useHistory();

  const fetchData = async () => {
    setLoading(true);
    try {
      const resp = await axios({
        url: baseUrl,
        method: "POST",
        headers: { Authorization: "Bearer " + token },
        data: { user_id: user_Id },
      });
      setRes(resp);
      setCvLink(resp.data.data.user_info.cv_file);
      setUser_id(resp.data.data.user_info.id);
      setName(resp.data.data.user_info.name);
      setApplicantsStatusId(resp.data.data.applicants[vacante].applicants_status_id);
      setJobStatusId(resp.data.data.applicants[vacante].job_status_id);
      setLoading(false);
      setError(null);
    } catch (error) {
      setError(error);
    } finally {
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  if (res !== null && applicant === null) {
    setApplicant(res.data.data.applicants[0].applicant_id);
  }
  const selectVacante = (i, applicantID) => {
    setVacante(i);
    setApplicant(applicantID);
    setShowChat(false);
    setApplicantsStatusId(res.data.data.applicants[i].applicants_status_id);
    setJobStatusId(res.data.data.applicants[i].job_status_id);
  };

  const fetchDelete = async () => {
    try {
      const response = await axios({
        url: baseUrlDelete,
        method: "POST",
        headers: { Authorization: "Bearer " + token },
        data: { user_id: user_Id },
      });
      setResolveEliminar(response.data.message);
      setLoading(false);
      if (response.data.success === 1) {
        setOk(true);
        // setShowDel(true)
      }
      setTimeout(() => {
        setOk(false);
        history.replace("/cms/auth/admin-vacantes");
      }, 2500);

      setError(null);
    } catch (error) {
      setError(error);
    } finally {
    }
  };

  const fetchRemove = async () => {
    try {
      const response = await axios({
        url: baseUrlRemove,
        method: "POST",
        headers: { Authorization: "Bearer " + token },
        data: { applicant_id: applicant, user_id: user_id },
      });

      if(response.status === 200){
          toast.success(response.data.message);
          setTimeout(() => {
              history.replace("/cms/auth/admin-vacantes");
          }, 3000);
      }

      return false;

      setResStatus(response);

      setLoading(false);
      if (response.data.success === 1) {
        setOk(true);
      }
      setTimeout(() => {
        setOk(false);
        fetchData();
      }, 2500);

      setError(null);
    } catch (error) {
      setError(error);
    } finally {
    }
  };

  const fetchDeleteAll = async () => {
    try {
      const response = await axios({
        url: baseUrlRemove,
        method: "POST",
        headers: { Authorization: "Bearer " + token },
        data: { applicant_id: "delete", user_id: user_id },
      });

      if(response.status === 200){
          toast.success(response.data.message);
          setTimeout(() => {
              history.replace("/cms/auth/admin-vacantes");
          }, 3000);
      }

      return false;

      setResStatus(response);

      setLoading(false);
      if (response.data.success === 1) {
        setOk(true);
      }
      setTimeout(() => {
        setOk(false);
        fetchData();
      }, 2500);

      setError(null);
    } catch (error) {
      setError(error);
    } finally {
    }
  };

  const contrataste = () => {
    setShow(true);
  };
  const fetchStatus = async (n) => {
    try {
      const response = await axios({
        url: baseUrlStatus,
        method: "POST",
        headers: { Authorization: "Bearer " + token },
        data: { status_id: n, applicant_id: applicant },
      });
      setResStatus(response);
      setLoading(false);
      if (response.data.success === 1) {
        setOk(true);
      }
      setTimeout(() => {
        setOk(false);
        fetchData();
      }, 1500);
      setShow(false);
      setError(null);
    } catch (error) {
      setError(error);
    } finally {
    }
  };
  const fetchToBeContinued = async (continued) => {
    try {
      const response = await axios({
        url: baseUrlStatus,
        method: "POST",
        headers: { Authorization: "Bearer " + token },
        data: { status_id: 6, applicant_id: applicant, continued, user_id: user_Id },
      });
      setResStatus(response);
      setLoading(false);
      if (response.data.success === 1) {
        setOk(true);
      }
      setTimeout(() => {
        setOk(false);
        fetchData();
      }, 1500);
      setShow(false);
      setError(null);
    } catch (error) {
      setError(error);
    } finally {
    }
  };
  const fetchEmailGracias = async () => {
    try {
      const response = await axios({
        url: baseUrlEmailProceso,
        method: "POST",
        headers: { Authorization: "Bearer " + token },
        data: { user_id: user_id, messages: gracias.messages },
      });
      setResolve(response.message);
      setLoading(false);
      setTimeout(() => {
        fetchToBeContinued(false);
      }, 1400);
      setError(null);
      setShow(false);
      setShowSpinnerGracias(false);
    } catch (error) {
      setError(error);
    } finally {
    }
  };

  const fetchEmailGracias2 = () => {
      setShowSpinnerGracias(true);
      fetchEmailGracias();
  }

  const fetchEmailOportunidades = async () => {
    try {
      const response = await axios({
        url: baseUrlEmailProceso,
        method: "POST",
        headers: { Authorization: "Bearer " + token },
        data: { user_id: user_id, messages: oportunidades.messages },
      });
      setResolve(response.message);
      setLoading(false);
      setTimeout(() => {
        fetchToBeContinued(true);
      }, 1500);
      setError(null);
      setShow(false);
      setShowSpinnerOportunidades(false);
    } catch (error) {
      setError(error);
    } finally {
    }
  };

  const fetchEmailOportunidades2 = () => {
      setShowSpinnerOportunidades(true);
      fetchEmailOportunidades();
  }

  const fetchEmailEliminar = async () => {
    try {
      const response = await axios({
        url: baseUrlEmailReason,
        method: "POST",
        headers: { Authorization: "Bearer " + token },
        data: { user_id: user_id, reason: reason.messages },
      });
      setResolveEliminar(response.message);
      setLoading(false);
      setTimeout(() => {
        fetchDelete();
      }, 1500);
      setError(null);
    } catch (error) {
      setError(error);
    } finally {
    }
  };

  const onChangeGracias = (e) => {
    setGracias({ ...gracias, messages: e.target.value });
  };
  const onChangeOportunidades = (e) => {
    setOportunidades({ ...oportunidades, messages: e.target.value });
  };
  const onChangeReason = (e) => {
    setReason({ ...reason, messages: e.target.value });
  };
  const onChangeEliminar = (e) => {
    setErrors({ ...errors, razon: false });
    setEliminarCandidato({ ...eliminarCandidato, messages: e.target.value });
  };
  const validarReason = (e) => {
    if (reason !== null) {
      setEliminar(1);
    } else {
      setErrors({ ...errors, razon: true });
    }
  };

  /////      CHAT

  let app_id1 = null;
  if (applicant !== null) {
    app_id1 = applicant;
  }
  const ChatShow = () => {
    setShowChat(!showChat);

    // document.querySelector("#contenedor-chat").style.display = "block";
    // var objDiv = document.getElementById("chat");
    // objDiv.scrollTop = objDiv.scrollHeight;
  };

  const handleClose = () => {
    setShow(false);
    setShowDel(false);
    setShowCv(false);
    setCandidato(null);
    setEliminar(null);
    setResolveEliminar(null);
  };
  let divResponse = "";
  if(resolve !== null){
    divResponse = <> <br />
    <div className="text-center h4">
      Gracias. la información se envió de forma correcta
    </div></>;
  }

  useEffect(() => { // fix para forzar actualizacion de mensaje
    if(candidato === 3){
        let message = document.getElementById('txa-gracias').value;
        setGracias({ ...gracias, messages: message });
    }
    if(candidato === 4){
        let message = document.getElementById('txa-oportunidades').value;
        setOportunidades({ ...gracias, messages: message });
    }
  }, [candidato]);

    const [showComments, setShowComments] = useState(false);
    const [fetchSubmitEliminar, setFetchSubmitEliminar] = useState(false);

    const handleSubmitEliminar = async (e) => {
        e.preventDefault();
        setFetchSubmitEliminar(true);
        let userStorage = JSON.parse(localStorage.getItem('userStorage'));
        await axios({
            url: BACKEND + "api/cms/user-comment",
            method: "POST",
            headers: { Authorization: "Bearer " + token },
            data: { user_id: user_Id, comment: e.target.elements.comment.value, created_by: userStorage.name },
        })
        .then((response) => {
            let message = response.data.message;
            response.data.success === 1 ? toast.success('Candidato eliminado') : toast.warning(message);
        })
        .catch((error) => {
            console.log(error);
            toast.error(error);
        })
        .finally((e) => {
            setShowDel(false);
            fetchDelete();
            setFetchSubmitEliminar(false);
        });
    }

    const cerrarOferta = async () => {
        setCandidato(5);
    }

    const contratarCandidato = async (close) => {
        let job_id = res.data.data.applicants[vacante].job_id;
        try {
            const response = await axios({
                url: baseUrlStatus,
                method: "POST",
                headers: { Authorization: "Bearer " + token },
                data: { status_id: 7, applicant_id: applicant, job_id: job_id, user_id: user_Id, close_offer: close },
            });
            setResStatus(response);
            setLoading(false);
            if (response.data.success === 1) {
                setOk(true);
                setTimeout(() => {
                    history.replace("/cms/auth/admin-vacantes");
                }, 3000);
            }
            setTimeout(() => {
                setOk(false);
                fetchData();
            }, 1500);
            setShow(false);
            setError(null);
        } catch (error) {
            setError(error);
        }
    }

  return (
    <>
      <SubNavBack />
      <ToastContainer />
      <Comments
          show={showComments}
          setShow={setShowComments}
          userId={user_Id}
          toast={toast}
       />
      <div className="container-fluid  bg-back">
        <div className="row justify-content-center">
          <div className="col-10  bg-white bgShadow mb-3">
            <div className="row justify-content-center ">
              <div className="status-hola text-left col-10 mt-md-5 pb-md-3">
                ¡HOLA DEPORTISTA!
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-2 col-sm-3" id="profile-img">
                <img
                  className="status-img-sabri"
                  src={
                    res !== null && res.data.data.user_info.photo_user !== null
                      ? res.data.data.user_info.photo_user
                      : defaul
                  }
                  alt="Decatips"
                />
              </div>
              <div className="col-10 pl-5 pl-md-0 col-md-4 ">
                <span className="status-name">
                  {res !== null &&
                    res.data.data.user_info.name +
                      " " +
                      res.data.data.user_info.last_name}
                </span>
                <br />
                <span className="status-email">
                  {res !== null && " " + res.data.data.user_info.email}{" "}
                </span>
                <br />
                <br />
                <span className="status-desc">
                  {" "}
                  <b>Edad:</b>
                  {res !== null && " " + res.data.data.user_info.age} años
                </span>
                <br />
                <span className="status-desc">
                  {" "}
                  <b> Género:</b>
                  {res !== null && " " + res.data.data.user_info.gender}
                </span>
                <br />
                <span className="status-desc">
                  <b>Ciudad:</b>{" "}
                  {res !== null && " " + res.data.data.user_info.city}
                </span>
                <br />
                <span className="status-desc">
                  <b>Estudios:</b>
                  {res !== null && " " + res.data.data.user_info.studies}
                </span>
                <br />
                <span className="status-desc">
                  <b>Disponibilidad:</b>
                  {res !== null && " " + res.data.data.user_info.schedule}
                </span>
                <br />
                <span className="status-desc">
                  <b>Teléfono: </b>
                  {res !== null && <a href={`https://api.whatsapp.com/send?phone=52${res.data.data.user_info.phone}`} target="_blank">{res.data.data.user_info.phone}</a> }
                </span>
                <br />
                <span className="status-desc">
                  <b>Método de contacto:</b>{" "}
                  {res !== null && " " + res.data.data.user_info.contact}
                </span>
                <br />
       
       
              </div>

              <div className="col-2 text-right  ">
                <div className="col-3 mb-4">
                  <div
                    className="buttonStatus"
                    onClick={() => setShowCv(true)}
                  >
                    <span className="">
                      Vista previa CV
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-2"></div>
            </div>


            <div className="row justify-content-center ">
              <div className="col-12 text-center status-vacante  mt-sm-5 mt-lg-4 mt-xl-3 mb-3  ">
                VACANTES
              </div>
            </div>
            <div className="row justify-content-center mt-4 ">
              <div
                style={{
                  height:
                    res !== null &&
                    res.data.data.applicants.length > 4 &&
                    "260px",
                }}
                className={
                  res !== null && res.data.data.applicants.length > 4
                    ? "col-12 col-md-5 txtVacantes overflow-auto"
                    : "col-12 col-md-5 txtVacantes "
                }
              >
                {res !== null &&
                  res.data.data.applicants.map((jobs, i) => (
                    <div
                      className={
                        res !== null && vacante === i
                          ? "col-12  text-left statusVacantes  bg-actv"
                          : "col-12  text-left statusVacantes "
                      }
                      key={i}
                      onClick={() => selectVacante(i, jobs.applicant_id)}
                    >
                      <div className="row justify-content-center">
                        <div className="col-12 my-1 ">{jobs.job_name}</div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            {res !== null && (
              <div className="container-fluid maxWidthCirculos ptProgress">
                <div className="row maxWidthCirculos">
                  <div className="bar-status-desk">

                    {res.data.data.applicants[vacante].applicant_status ===
                    "Candidatura visualizada" ? (
                      <span className="dot_1 dot_active"></span>
                    ) : (
                      <span className="dot_1 dot_not_active"></span>
                    )}
                    <p className="p-can-vis">
                      Candidatura visualizada{" "}
                      {res.data.data.applicants[vacante].applicant_status ===
                        "Candidatura visualizada" && (
                        <>
                          <img
                            className="selectedBuso"
                            src={
                              res.data.data.applicants[vacante].job_name.split(" ")[1] === "Montaña"
                                ? montaña
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Carrera y Caminata"
                                ? carrera
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Gimnasia y entrenamiento"
                                ? gimnasia
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Combate"
                                ? combate
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Acuáticos y Subacuáticos"
                                ? acuaticos
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Olas y Arrastre"
                                ? olas
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Colectivos"
                                ? colectivos
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Raqueta"
                                ? raqueta
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Ciclismo"
                                ? ciclismo
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Urbanos"
                                ? urbanos
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Naturaleza"
                                ? caza
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Precisión"
                                ? presicion
                                : buso
                            }
                            alt="Decatips"
                          />
                          <span className="selectedText">
                            {res !== null &&
                              "" + res.data.data.user_info.sport_1.deporte}

                            <img
                              className="selectedlvl"
                              src={
                                res !== null &&
                                res.data.data.user_info.sport_1.nivel === 1
                                  ? lvl1
                                  : res.data.data.user_info.sport_1.nivel === 2
                                  ? lvl2
                                  : res.data.data.user_info.sport_1.nivel === 3
                                  ? lvl3
                                  : undefined
                              }
                              alt="Decatips"
                            />
                          </span>
                        </>
                      )}
                    </p>
                    <div
                      className="diag_2"
                      style={{ width: "150px", height: "30px" }}
                    ></div>

                    <p className="p-can-con">
                      Candidato contactado{" "}
                      {res.data.data.applicants[vacante].applicant_status ===
                        "Candidato contactado" && (
                        <>
                          <img
                            className="selectedBuso"
                            src={
                              res.data.data.applicants[vacante].job_name.split(" ")[1] === "Montaña"
                                ? montaña
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Carrera y Caminata"
                                ? carrera
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Gimnasia y entrenamiento"
                                ? gimnasia
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Combate"
                                ? combate
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Acuáticos y Subacuáticos"
                                ? acuaticos
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Olas y Arrastre"
                                ? olas
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Colectivos"
                                ? colectivos
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Raqueta"
                                ? raqueta
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Ciclismo"
                                ? ciclismo
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Urbanos"
                                ? urbanos
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Naturaleza"
                                ? caza
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Precisión"
                                ? presicion
                                : buso
                            }
                            alt="Decatips"
                          />
                          <span className="selectedText">
                            {res !== null &&
                              "" + res.data.data.user_info.sport_1.deporte}

                            <img
                              className="selectedlvl"
                              src={
                                res !== null &&
                                res.data.data.user_info.sport_1.nivel === 1
                                  ? lvl1
                                  : res.data.data.user_info.sport_1.nivel === 2
                                  ? lvl2
                                  : res.data.data.user_info.sport_1.nivel === 3
                                  ? lvl3
                                  : undefined
                              }
                              alt="Decatips"
                            />
                          </span>
                        </>
                      )}
                    </p>
                    {res.data.data.applicants[vacante].applicant_status ===
                    "Candidato contactado" ? (
                      <span className="dot dot_active"></span>
                    ) : (
                      <span className="dot dot_not_active"></span>
                    )}
                    <div
                      className="diag_1"
                      style={{ width: "150px", height: "30px" }}
                    ></div>

                    {res.data.data.applicants[vacante].applicant_status ===
                    "Candidato entrevistado" ? (
                      <span className="dot_1 dot_active"></span>
                    ) : (
                      <span className="dot_1 dot_not_active"></span>
                    )}
                    <p className="p-can-ent">
                      Candidato entrevistado{" "}
                      {res.data.data.applicants[vacante].applicant_status ===
                        "Candidato entrevistado" && (
                        <>
                          <img
                            className="selectedBuso"
                            src={
                              res.data.data.applicants[vacante].job_name.split(" ")[1] === "Montaña"
                                ? montaña
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Carrera y Caminata"
                                ? carrera
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Gimnasia y entrenamiento"
                                ? gimnasia
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Combate"
                                ? combate
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Acuáticos y Subacuáticos"
                                ? acuaticos
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Olas y Arrastre"
                                ? olas
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Colectivos"
                                ? colectivos
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Raqueta"
                                ? raqueta
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Ciclismo"
                                ? ciclismo
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Urbanos"
                                ? urbanos
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Naturaleza"
                                ? caza
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Precisión"
                                ? presicion
                                : buso
                            }
                            alt="Decatips"
                          />
                          <span className="selectedText">
                            {res !== null &&
                              "" + res.data.data.user_info.sport_1.deporte}

                            <img
                              className="selectedlvl"
                              src={
                                res !== null &&
                                res.data.data.user_info.sport_1.nivel === 1
                                  ? lvl1
                                  : res.data.data.user_info.sport_1.nivel === 2
                                  ? lvl2
                                  : res.data.data.user_info.sport_1.nivel === 3
                                  ? lvl3
                                  : undefined
                              }
                              alt="Decatips"
                            />
                          </span>
                        </>
                      )}
                    </p>
                    <div
                      className="diag_2"
                      style={{ width: "150px", height: "30px" }}
                    ></div>

                

                    {res.data.data.applicants[vacante].applicant_status ===
                    "Proceso de selección finalizado" ? (
                      <span className="dot dot_active"></span>
                    ) : (
                      <span className="dot dot_not_active"></span>
                    )}
                    <p className="p-fin">
                      Proceso finalizado{" "}
                      {res.data.data.applicants[vacante].applicant_status ===
                        "Proceso de selección finalizado" && (
                        <>
                          <img
                            className="selectedBuso"
                            src={
                              res.data.data.applicants[vacante].job_name.split(" ")[1] === "Montaña"
                                ? montaña
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Carrera y Caminata"
                                ? carrera
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Gimnasia y entrenamiento"
                                ? gimnasia
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Combate"
                                ? combate
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Acuáticos y Subacuáticos"
                                ? acuaticos
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Olas y Arrastre"
                                ? olas
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Colectivos"
                                ? colectivos
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Raqueta"
                                ? raqueta
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Ciclismo"
                                ? ciclismo
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Urbanos"
                                ? urbanos
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Naturaleza"
                                ? caza
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Precisión"
                                ? presicion
                                : buso
                            }
                            alt="Decatips"
                          />
                          <span className="selectedText">
                            {res !== null &&
                              "" + res.data.data.user_info.sport_1.deporte}

                            <img
                              className="selectedlvl"
                              src={
                                res !== null &&
                                res.data.data.user_info.sport_1.nivel === 1
                                  ? lvl1
                                  : res.data.data.user_info.sport_1.nivel === 2
                                  ? lvl2
                                  : res.data.data.user_info.sport_1.nivel === 3
                                  ? lvl3
                                  : undefined
                              }
                              alt="Decatips"
                            />
                          </span>
                        </>
                      )}
                    </p>
                  </div>

                  <div className="bar-status-resp ">

                    <p className="p-can-vis">
                      Candidatura visualizada{" "}
                      {res.data.data.applicants[vacante].applicant_status ===
                        "Candidatura visualizada" && (
                        <>
                          <img
                            className="selectedBuso"
                            src={
                              res.data.data.applicants[vacante].job_name.split(" ")[1] === "Montaña"
                                ? montaña
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Carrera y Caminata"
                                ? carrera
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Gimnasia y entrenamiento"
                                ? gimnasia
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Combate"
                                ? combate
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Acuáticos y Subacuáticos"
                                ? acuaticos
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Olas y Arrastre"
                                ? olas
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Colectivos"
                                ? colectivos
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Raqueta"
                                ? raqueta
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Ciclismo"
                                ? ciclismo
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Urbanos"
                                ? urbanos
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Naturaleza"
                                ? caza
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Precisión"
                                ? presicion
                                : buso
                            }
                            alt="Decatips"
                          />
                          <span className="selectedText">
                            Acuáticos y subacuáticos
                            <img
                              className="selectedlvl"
                              src={
                                res !== null &&
                                res.data.data.user_info.sport_1.nivel === 1
                                  ? lvl1
                                  : res.data.data.user_info.sport_1.nivel === 2
                                  ? lvl2
                                  : res.data.data.user_info.sport_1.nivel === 3
                                  ? lvl3
                                  : undefined
                              }
                              alt="Decatips"
                            />
                          </span>
                        </>
                      )}
                    </p>
                    {res.data.data.applicants[vacante].applicant_status ===
                    "Candidatura visualizada" ? (
                      <span className="dot_1 dot_active"></span>
                    ) : (
                      <span className="dot_1 dot_not_active"></span>
                    )}
                    <div
                      className="diag_1"
                      style={{ width: "40px", height: "150px" }}
                    ></div>

                    <p className="p-can-con">
                      Candidato contactado{" "}
                      {res.data.data.applicants[vacante].applicant_status ===
                        "Candidato contactado" && (
                        <>
                          <img
                            className="selectedBuso"
                            src={
                              res.data.data.applicants[vacante].job_name.split(" ")[1] === "Montaña"
                                ? montaña
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Carrera y Caminata"
                                ? carrera
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Gimnasia y entrenamiento"
                                ? gimnasia
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Combate"
                                ? combate
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Acuáticos y Subacuáticos"
                                ? acuaticos
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Olas y Arrastre"
                                ? olas
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Colectivos"
                                ? colectivos
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Raqueta"
                                ? raqueta
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Ciclismo"
                                ? ciclismo
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Urbanos"
                                ? urbanos
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Naturaleza"
                                ? caza
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Precisión"
                                ? presicion
                                : buso
                            }
                            alt="Decatips"
                          />
                          <span className="selectedText">
                            Acuáticos y subacuáticos
                            <img
                              className="selectedlvl"
                              src={
                                res !== null &&
                                res.data.data.user_info.sport_1.nivel === 1
                                  ? lvl1
                                  : res.data.data.user_info.sport_1.nivel === 2
                                  ? lvl2
                                  : res.data.data.user_info.sport_1.nivel === 3
                                  ? lvl3
                                  : undefined
                              }
                              alt="Decatips"
                            />
                          </span>
                        </>
                      )}
                    </p>
                    {res.data.data.applicants[vacante].applicant_status ===
                    "Candidatura contactado" ? (
                      <span className="dot dot_active"></span>
                    ) : (
                      <span className="dot dot_not_active"></span>
                    )}
                    <div
                      className="diag_2"
                      style={{ width: "40px", height: "150px" }}
                    ></div>

                    <p className="p-can-ent">
                      Candidato entrevistado{" "}
                      {res.data.data.applicants[vacante].applicant_status ===
                        "Candidato entrevistado" && (
                        <>
                          <img
                            className="selectedBuso"
                            src={
                              res.data.data.applicants[vacante].job_name.split(" ")[1] === "Montaña"
                                ? montaña
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Carrera y Caminata"
                                ? carrera
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Gimnasia y entrenamiento"
                                ? gimnasia
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Combate"
                                ? combate
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Acuáticos y Subacuáticos"
                                ? acuaticos
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Olas y Arrastre"
                                ? olas
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Colectivos"
                                ? colectivos
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Raqueta"
                                ? raqueta
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Ciclismo"
                                ? ciclismo
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Urbanos"
                                ? urbanos
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Naturaleza"
                                ? caza
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Precisión"
                                ? presicion
                                : buso
                            }
                            alt="Decatips"
                          />
                          <span className="selectedText">
                            Acuáticos y subacuáticos
                            <img
                              className="selectedlvl"
                              src={
                                res !== null &&
                                res.data.data.user_info.sport_1.nivel === 1
                                  ? lvl1
                                  : res.data.data.user_info.sport_1.nivel === 2
                                  ? lvl2
                                  : res.data.data.user_info.sport_1.nivel === 3
                                  ? lvl3
                                  : undefined
                              }
                              alt="Decatips"
                            />
                          </span>
                        </>
                      )}
                    </p>
                    {res.data.data.applicants[vacante].applicant_status ===
                    "Candidatura entrevistado" ? (
                      <span className="dot_1 dot_active"></span>
                    ) : (
                      <span className="dot_1 dot_not_active"></span>
                    )}
                    <div
                      className="diag_1"
                      style={{ width: "40px", height: "150px" }}
                    ></div>


                    <p className="p-fin">
                      Proceso de selección finalizado{" "}
                      {res.data.data.applicants[vacante].applicant_status ===
                        "Proceso de selección finalizado" && (
                        <>
                          <img
                            className="selectedBuso"
                            src={
                              res.data.data.applicants[vacante].job_name.split(" ")[1] === "Montaña"
                                ? montaña
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Carrera y Caminata"
                                ? carrera
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Gimnasia y entrenamiento"
                                ? gimnasia
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Combate"
                                ? combate
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Acuáticos y Subacuáticos"
                                ? acuaticos
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Olas y Arrastre"
                                ? olas
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Colectivos"
                                ? colectivos
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Raqueta"
                                ? raqueta
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Ciclismo"
                                ? ciclismo
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Urbanos"
                                ? urbanos
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Naturaleza"
                                ? caza
                                : res.data.data.applicants[vacante].job_name.split(" ")[1] ===
                                  "Precisión"
                                ? presicion
                                : buso
                            }
                            alt="Decatips"
                          />
                          <span className="selectedText">
                            Acuáticos y subacuáticos
                            <img
                              className="selectedlvl"
                              src={
                                res !== null &&
                                res.data.data.user_info.sport_1.nivel === 1
                                  ? lvl1
                                  : res.data.data.user_info.sport_1.nivel === 2
                                  ? lvl2
                                  : res.data.data.user_info.sport_1.nivel === 3
                                  ? lvl3
                                  : undefined
                              }
                              alt="Decatips"
                            />
                          </span>
                        </>
                      )}
                    </p>
                    {res.data.data.applicants[vacante].applicant_status ===
                    "Proceso de selección finalizado" ? (
                      <span className="dot dot_active"></span>
                    ) : (
                      <span className="dot dot_not_active"></span>
                    )}
                  </div>
                </div>
              </div>
            )}
            {res !== null && ""}
            <div className="row">

              <div className="col-3 mb-4">
                <div
                    className={`buttonStatus${applicantsStatusId === 2 && jobStatusId !== 2 ? 'Orange' : applicantsStatusId === 6 || jobStatusId === 2 ? 'Disabled' : ''}`}
                    onClick={() => applicantsStatusId < 6 && jobStatusId !== 2 && fetchStatus(2)}
                >
                  <span className="">
                    CANDIDATURA
                    <br />
                    VISUALIZADA
                  </span>
                </div>
              </div>
              <div className="col-3 mb-4">
                <div
                    className={`buttonStatus${applicantsStatusId === 3 && jobStatusId !== 2 ? 'Orange' : applicantsStatusId === 6 || jobStatusId === 2 ? 'Disabled' : ''}`}
                    onClick={() => applicantsStatusId < 6 && jobStatusId !== 2 && fetchStatus(3)}
                >
                  <span className="">
                    CANDIDATO <br />
                    CONTACTADO
                  </span>
                </div>
              </div>
              <div className="col-3 mb-4">
                <div
                    className={`buttonStatus${applicantsStatusId === 4 && jobStatusId !== 2 ? 'Orange' : applicantsStatusId === 6 || jobStatusId === 2 ? 'Disabled' : ''}`}
                    onClick={() => applicantsStatusId < 6 && jobStatusId !== 2 && fetchStatus(4)}
                >
                  <span className="">
                    CANDIDATO <br />
                    ENTREVISTADO
                  </span>
                </div>
              </div>
              <div className="col-3 mb-4">
                { jobStatusId !== 2 ?
                    <div
                        className={`buttonStatus${applicantsStatusId === 6 ? 'Orange' : ''}`}
                        onClick={() => applicantsStatusId <= 6 && contrataste()}
                    >
                      <span className="">
                        CANDIDATO 
                        <br /> CONTRATADO
                      </span>
                    </div>
                :
                    <div className={`buttonStatusWarning`}>
                      <span className="">
                        VACANTE CERRADA
                      </span>
                    </div>
                }
              </div>
            </div>
            
           
            <div className="row">
              <div className="col-3 mb-4">
                <div
                  className="buttonStatus"
                   onClick={() => setShowDel(true)}
                >
                  <span className="">
                    ENVIAR CANDIDATO
                    <br /> A BLACK-LIST
                  </span>
                </div>
              </div>
              <div className="col-3 mb-4">
                <div
                    className="buttonStatus"
                    onClick={() => fetchDeleteAll()}
                  >
                    ELIMINAR CANDIDATO
                </div>
              </div>
              <div className="col-3 mb-4">
                <div
                    className="buttonStatus"
                    onClick={() => fetchRemove()}
                  >
                    MOVER CANDIDATO A BASE DE DATOS CV
                </div>
              </div>
              <div className="col-3 mb-4">
                <div
                  className="buttonStatus"
                   onClick={() => setShowComments(true)}
                >
                  <span className="">
                    COMENTARIOS
                  </span>
                </div>
              </div>
            </div>




            <div className="row">
              <div className="col-3"></div>
              <div className="col-7">
                {resStatus !== null && ok === true ? (
                  <div
                    className="alert alert-success alert-dismissible "
                    role="alert"
                  >
                    <strong>Gracias:</strong> Proceso actualizado correctamente
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-2"></div>
            </div>

            <div className="row  mt-3 mb-4">
              <div className="col-12 d-none">
                <div
                  className="btnMoverCandidato text-center  mx-auto"
                  onClick={() => ChatShow()}
                >
                  CONTACTARME CON EL DEPORTISTA
                </div>
              </div>
              {showChat === true && <ChatBack app_id={applicant} />}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className="bgBlue" id="nayeli">
          <div className=" container">
            <div className="titBack text-center col-12 my-1 text-white ">
              CONFIRMACIÓN
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          {resolve === null && (
            <div className="row justify-content-center">
              <div className="col-11">
                {candidato === null && (
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label htmlFor="inputEmail4" className="titInputBack">
                        <br />
                        ¿Contrataste al este/a candidato/a?
                      </label>
                      <br /> <br />
                    </div>
                  </div>
                )}
                {candidato === 1 && (
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label htmlFor="inputEmail4" className="titInputBack">
                        <br />
                        ¿Puede este candidato formar parte de otro proceso de
                        selección en Decathlon?
                      </label>
                      <br /> <br />
                    </div>
                  </div>
                )}
                {candidato === 3 && (
                  <>
                    <form>
                      <div className="form-row">
                        <div className="form-group col-md-12">
                          <label htmlFor="inputEmail4" className="titInputBack">
                            Email para el candidato
                          </label>
                          <textarea
                            type="text"
                            className="form-control"
                            onChange={onChangeGracias}
                            defaultValue={`Hola ${name}. Ante todo, gracias por tu interés en trabajar en Decathlon. Este mail es para informarte de que, después de evaluar tu candidatura, tu perfil no se ajusta completamente a nuestras necesidades. Muchas gracias por el tiempo invertido. Un saludo!`}
                            rows="13"
                            id="txa-gracias"
                          />
                        </div>
                      </div>
                    </form>
                  </>
                )}
                {candidato === 4 && (
                  <>
                    <form>
                      <div className="form-row">
                        <div className="form-group col-md-12">
                          <label htmlFor="inputEmail4" className="titInputBack">
                            Email para el candidato
                          </label>
                          <textarea
                            type="text"
                            className="form-control"
                            rows="13"
                            onChange={onChangeOportunidades}
                            defaultValue={`Hola ${name}. Ante todo, gracias por tu interés en trabajar en Decathlon y por el tiempo que has dedicado a este proceso de selección. Este mail es para informarte de que tu perfil no se ajusta completamente a esta oferta pero… No te rindas! Hemos dejado tu candidatura en nuestra base de datos para que tengas nuevas oportunidades. Un saludo!`}
                            id="txa-oportunidades"
                          />
                        </div>
                      </div>
                    </form>
                  </>
                )}
                {candidato === 5 && (
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label htmlFor="inputEmail4" className="titInputBack">
                        <br />
                        ¿Deseas mantener activa la oferta de trabajo?
                      </label>
                      <br /> <br />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {divResponse}


        </Modal.Body>
        {resolve === null && (
          <Modal.Footer>
            {candidato === null && (
              <>
                {" "}
                <Button variant="primary" onClick={cerrarOferta}>
                  Si
                </Button>
              </>
            )}
            {candidato === 1 && (
              <>
                {" "}
                <Button
                  variant="danger"
                  onClick={() => {
                    setCandidato(3);
                  }}
                >
                  No
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    setCandidato(4);
                  }}
                >
                  Si
                </Button>
              </>
            )}
            {candidato === 3 && (
              <>
                {" "}
                <Button
                  variant="danger"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    fetchEmailGracias2();
                  }}
                  disabled={showSpinnerGracias}
                >
                    { showSpinnerGracias &&
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                    }
                    &nbsp;Enviar y confirmar
                </Button>
              </>
            )}
            {candidato === 4 && (
              <>
                {" "}
                <Button
                  variant="danger"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    fetchEmailOportunidades2();
                  }}
                  disabled={showSpinnerOportunidades}
                >
                    { showSpinnerOportunidades &&
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                    }
                    &nbsp;Enviar y confirmar
                </Button>
              </>
            )}

            {candidato === 5 && (
              <>
                {" "}
                <Button
                  variant="danger"
                  onClick={() => {
                    contratarCandidato(true);
                  }}
                >
                  No
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    contratarCandidato(false);
                  }}
                >
                  Si
                </Button>
              </>
            )}

          </Modal.Footer>
        )}
      </Modal>
      <Modal
        show={showCv}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header closeButton className="bgBlue" id="cv">
          <div className=" container">
            <div className="titBack text-center col-12 my-1 text-white ">
              Vista previa CV
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <iframe
            width="1100" 
            height="600"
            src={cvLink}
            // src="https://trabaja-con-nosotros-decathlon-mexico.s3.amazonaws.com/production/cv/QXKX6SE1dplyLPEuVnXWldD_cv.pdf?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAVZJJA6BNGSBKMT6F%2F20231114%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20231114T164557Z&X-Amz-SignedHeaders=host&X-Amz-Expires=98400&X-Amz-Signature=69c0a33d2302ed7ea60c98533f727592f903eae97006680db68ac90ae0aa1ffb"
            title="CV"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Modal.Body>
      </Modal>
      <Modal
        show={showDel}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className="bgBlue" id="diego">
          <div className=" container">
            <div className="titBack text-center col-12 my-1 text-white ">
              CONFIRMACIÓN
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          {resolveEliminar === null && (
            <div className="row justify-content-center">
              <div className="col-11">
                {eliminar === null && (
                  <form onSubmit={handleSubmitEliminar}>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label htmlFor="inputEmail4" className="titInputBack">
                          Explica cuál es la limitante para que este candidato
                          no pueda trabajar en Decathlon
                        </label>
                        <textarea
                          type="text"
                          className="form-control"
                          name="comment"
                          rows="13"
                          onChange={onChangeReason}
                          placeholder="Escriba la limitante..."
                          required
                          maxLength="650"
                        />
                        {errors !== null && errors.razon === true && (
                          <span className="text-danger ">
                            Por favor escriba la limitante
                          </span>
                        )}
                        <Button variant="secondary" className="float-right mt-2 mb-n3" onClick={() => setShowDel(false)}>Cancelar</Button>
                        <Button
                            variant="primary"
                            type="submit"
                            className="float-right mt-2 mb-n3 mr-1"
                            disabled={fetchSubmitEliminar}
                            onClick={() => fetchEmailEliminar()}
                        >
                            Eliminar
                        </Button>
                      </div>
                    </div>

                  </form>
                )}
                {eliminar === 1 && (
                  <form>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label htmlFor="inputEmail4" className="titInputBack">
                          Enviar mensaje a {name}
                        </label>
                        <div className="text-justify">
                          Hola {name}.
                          <br /> Ante todo, gracias por tu interés en trabajar
                          en Decathlon. Este mail es para informarte de que,
                          después de evaluar tu candidatura, tu perfil no se
                          ajusta completamente a nuestras necesidades. Muchas
                          gracias por el tiempo invertido. Un saludo!
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          )}
          {resolveEliminar !== null && (
            <>
              {" "}
              <br />
              <LoaderChico /> <br /> <br /> <br />
              <div className="text-center h4">
                Gracias, la información se envió de forma correcta.
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {resolveEliminar !== null && (
            <>
              {eliminar === null && (
                <>
                  <Button
                    variant="danger"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button variant="primary" onClick={(e) => validarReason(e)}>
                    Continuar
                  </Button>
                </>
              )}
              {eliminar === 1 && (
                <>
                  <Button
                    variant="danger"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => fetchEmailEliminar()}
                  >
                    Enviar mensaje
                  </Button>
                </>
              )}
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminStatus;