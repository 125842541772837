import React, { useEffect, useRef, useState } from "react";
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css';
import * as L from 'leaflet';
import 'leaflet-defaulticon-compatibility';
import marker from "../components/images/alfiler.png";

const style = {
  width: "100%",
  height: "600px"
};

function Map({ markerPosition, onMarkerClick, onMapClick, centerPosition, zoomLevel, onReset }) {
    const mapRef = useRef(null);
    const markerLayerRef = useRef(null);
    const [map, setMap] = useState(null);

    useEffect(() => {
        if (!mapRef.current) {
            mapRef.current = L.map("map", {
                center: [19.4311768, -99.2047097],
                zoom: 5,
                layers: [
                    L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
                        attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                    })
                ]
            });

            setMap(mapRef.current);

            markerLayerRef.current = L.layerGroup().addTo(mapRef.current);

            mapRef.current.on('click', (event) => {
                if (onMapClick) {
                    onMapClick(event.latlng);
                }
            });
        }

        if (centerPosition) {
            mapRef.current.setView(centerPosition, zoomLevel);
        }

    }, [centerPosition, zoomLevel, onMapClick]);

    useEffect(() => {
        if (mapRef.current && markerLayerRef.current) {
            markerLayerRef.current.clearLayers();

            const locationCount = markerPosition.reduce((acc, loc) => {
                const key = `${loc.lat},${loc.lng}`;
                if (!acc[key]) {
                    acc[key] = { ...loc, count: 1 };
                } else {
                    acc[key].count += 1;
                }
                return acc;
            }, {});

            Object.values(locationCount).forEach(loc => {
                L.marker([loc.lat, loc.lng], {
                    icon: new L.divIcon({
                        html: `
                       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="40" height="40">
                            <path d="M12 2C7.03 2 4 6 4 10.5c0 2.85 1.31 5.56 3.56 7.31l4.44 4.42 4.44-4.42c2.25-1.75 3.56-4.46 3.56-7.31C20 6 16.97 2 12 2z" fill="#3643ba"/>
                            <text x="50%" y="50%" text-anchor="middle" fill="white" font-size="13px" font-family="Arial" dy=".3em">${loc.count}</text>
                        </svg>
                        `,
                        className: "",
                        iconSize: [24, 40],
                        iconAnchor: [12, 40],
                    })
                })
                .addTo(markerLayerRef.current)
                .on('click', () => onMarkerClick(loc));
            });
        }
    }, [markerPosition, onMarkerClick]);

    const resetZoom = () => {
        if (map) {
            map.setView([19.4311768, -99.2047097], 5);
        }
        if (onReset) {
            onReset(); 
        }
    };

    return (
        <div>
            <button 
                onClick={resetZoom} 
                style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    backgroundColor: 'white',
                    padding: '10px',
                    borderRadius: '5px',
                    border: '1px solid #ccc',
                    cursor: 'pointer',
                    zIndex: '9'
                }}
            >
                Reset Zoom
            </button>
            <div id="map" style={style} />
        </div>
    );
}

export default Map;
